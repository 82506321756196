import { EMBED } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, EMBED_ANNOUNCEMENT } from './defaults';

export default {
  id: EMBED_ANNOUNCEMENT,
  name: 'Announcement',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: EMBED,
  isDefault: true,
  content: {
    id: 'dcf37cb4-809d-4afa-97e4-7c5bdc753d63',
    type: 'group',
    children: [
      {
        id: 'e3f91be5-92c6-4397-8f83-7c4c9b986c5d',
        type: 'embed',
        content: {
          id: 'ba655f8b-da31-481f-92b0-1da537009421',
          items: [
            {
              distribution: 'equal',
              id: 'b179aee4-36fa-46f5-b06d-9912abced0f5',
              items: [
                {
                  blockType: 'text',
                  content: {
                    id: 'e599a3aa-8579-46fe-8be1-6bba98ff76b6',
                    style: {
                      fontName: 'System Default Bold',
                      fontSize: 14,
                      foregroundColor: {
                        light: '#0B1A38',
                      },
                      horizontalAlignment: 'leading',
                      marginBottom: 8,
                      marginLeading: 16,
                      marginTop: 8,
                      marginTrailing: 16,
                      textAlignment: 'leading',
                    },
                    text: 'Scheduled maintenance from 22:00-23:00 UTC',
                    type: 'text',
                  },
                  id: '11946a64-ebaa-4615-b1c3-2bf734474491',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {},
        traits: [],
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          presentationStyle: 'embed',
          style: {
            backgroundColor: {
              light: '#FFF0B3',
            },
            paddingBottom: 0,
          },
          transition: 'fade',
        },
        type: '@appcues/embedded',
      },
      {
        config: {
          buttonAppearance: 'minimal',
          buttonStyle: {
            marginBottom: 2,
            marginLeading: 2,
            marginTop: 2,
            marginTrailing: 2,
          },
        },
        type: '@appcues/skippable',
      },
      {
        type: '@appcues/carousel',
      },
      {
        config: {
          style: {
            backgroundColor: {
              light: '#CCCCCC',
            },
            foregroundColor: {
              light: '#999999',
            },
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
          },
        },
        type: '@appcues/paging-dots',
      },
    ],
  },
};
