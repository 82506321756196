import { createLifecycleFor } from 'ext/lib/collections';

export const TYPE = 'screenshots';

export const { insert, patterns, reject, resolve } = createLifecycleFor(TYPE);

export const LOAD_TEMPLATE_SCREENSHOT = '@screenshot/LOAD_TEMPLATE_SCREENSHOT';
export const loadTemplateScreenshot = payload => ({
  type: LOAD_TEMPLATE_SCREENSHOT,
  payload,
});
