/* eslint-disable unicorn/filename-case */
import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import PropTypes from 'prop-types';
import { Button } from '@appcues/sonar';
import { InputClickToCopy } from 'ext/components/ui';
import { Form, FormGroup, Label } from 'components/Form';
import { QRCodeWrapper } from './styled';

const QRCodeInput = ({ ariaLabel, url, onClick }) => (
  <>
    <Form aria-label={ariaLabel} role="form">
      <FormGroup>
        <Label>Scan this QR Code:</Label>
        <QRCodeWrapper>
          <QRCodeSVG aria-label="URL QR Code image" size={225} value={url} />
        </QRCodeWrapper>
      </FormGroup>
      <FormGroup>
        <Label>Or open this URL on your device:</Label>
        <InputClickToCopy text={url} wrap />
      </FormGroup>
    </Form>
    <Button kind="primary" onClick={onClick} fluid>
      Done
    </Button>
  </>
);

QRCodeInput.propTypes = {
  ariaLabel: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default QRCodeInput;
