import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { selectExperience } from 'entities/experiences';
import { selectStepGroup } from 'entities/step-groups';
import {
  sanitize,
  selectStepChild,
  update as updateStepChild,
} from 'entities/step-children';
import { selectStepGroupFirstChild } from 'lib/selectors';
import {
  hasPreviousAction,
  transformPreviousActionIntoNext,
} from 'lib/actions';
import {
  flush,
  insert,
  movePatterns,
  patterns,
  prune,
  STEP_GROUP_CLONED,
} from './actions';
import { transform, revert } from './schema';

function* cloneStepChildToNewGroup({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { parentId, childId } = payload;
    const { id: experienceId } = yield select(selectExperience);
    const api = yield getContext('api');
    const stepParent = yield select(selectStepGroup, parentId);
    const stepChild = yield select(selectStepChild, childId);

    // Revert UI editor config to canonical entity values
    const updatedStepGroup = yield call(revert, stepParent);

    const newStepGroup = {
      ...sanitize(updatedStepGroup),
      children: [sanitize(stepChild)],
    };

    const response = yield call(api.createStep, experienceId, newStepGroup);
    yield put(insert(transform(response), experienceId));
  } catch (error) {
    logger.error(error);
  }
}

function* updateStepGroup({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id: stepGroupId } = payload;
    const api = yield getContext('api');
    const { id } = yield select(selectExperience);
    const stepGroup = yield select(selectStepGroup, stepGroupId);

    // Revert UI editor config to canonical entity values
    const updated = yield call(revert, stepGroup);
    yield call(api.updateStep, id, stepGroupId, updated);

    yield put(flush(stepGroupId));
  } catch (error) {
    logger.error(error);
  }
}

function* moveStepChild({ payload }) {
  const logger = yield getContext('logger');
  try {
    const {
      to: toIndex,
      child: stepChildId,
      parent: stepGroupId,
      targetStepId,
      action,
    } = payload;
    const data = { targetStepId, action };
    const api = yield getContext('api');
    const { id: experienceId } = yield select(selectExperience);
    const { actions } = yield select(
      selectStepGroupFirstChild,
      stepGroupId,
      stepChildId
    );

    // If a step is moved to the first position and it has a previous action,
    // we need to transform it into a next action to trigger the following steps.
    if (toIndex === 0 && hasPreviousAction(actions)) {
      const updatedActions = transformPreviousActionIntoNext(actions);
      const delta = { actions: updatedActions };
      yield put(updateStepChild({ id: stepChildId, delta }));
    }

    yield call(api.moveStep, experienceId, stepChildId, data);
    yield put(flush(stepGroupId));
  } catch (error) {
    logger.error(error);
  }
}

function* removeStepGroup({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id: stepGroupId } = payload;
    const { id: experienceId } = yield select(selectExperience);
    const api = yield getContext('api');

    yield call(api.removeStep, experienceId, stepGroupId);
    yield put(prune(stepGroupId));
  } catch (error) {
    logger.error(error);
  }
}

export default function* stepGroupsSaga() {
  yield takeEvery(STEP_GROUP_CLONED, cloneStepChildToNewGroup);
  yield takeEvery(patterns.update, updateStepGroup);
  yield takeEvery(patterns.remove, removeStepGroup);
  yield takeEvery(movePatterns.move, moveStepChild);
}
