import React from 'react';

const PlaceholderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 600 400"
  >
    <path
      fill="#1F2F4F"
      d="M234.539 148.5v103h130.922v-103H234.539zm120.369 91.83H245.707v-80.661h109.201v80.661z"
    />
    <path
      fill="#1F2F4F"
      d="m251.58 231.643 22.508-23.659 8.433 3.649 26.609-28.325 10.474 12.528 4.725-2.854 25.569 38.661z"
    />
    <circle fill="#1F2F4F" cx="277.582" cy="180.18" r="9.83" />
  </svg>
);

export default PlaceholderIcon;
