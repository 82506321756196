export const TYPE = 'userInterface';

export const USER_INTERFACE_PREVIEW_UPDATED = 'USER_INTERFACE_PREVIEW_UPDATED';
export const updatePreview = ({ blockId, previewType, show }) => ({
  type: USER_INTERFACE_PREVIEW_UPDATED,
  payload: {
    blockId,
    previewType,
    show,
  },
});

export const USER_INTERFACE_TARGET_PLACEMENT_UPDATED =
  'USER_INTERFACE_TARGET_PLACEMENT_UPDATED';
export const updateTargetPlacement = () => ({
  type: USER_INTERFACE_TARGET_PLACEMENT_UPDATED,
});

export const USER_INTERFACE_SIDE_BAR_TAB_UPDATED =
  'USER_INTERFACE_SIDE_BAR_TAB_UPDATED';
export const updateSideBarTab = ({ stepGroup, tab }) => ({
  type: USER_INTERFACE_SIDE_BAR_TAB_UPDATED,
  payload: {
    stepGroup,
    tab,
  },
  meta: { type: TYPE },
});

export const USER_INTERFACE_FLOATING_SCREEN_TOGGLE =
  'USER_INTERFACE_FLOATING_SCREEN_TOGGLE';
export const toggleFloatingScreen = () => ({
  type: USER_INTERFACE_FLOATING_SCREEN_TOGGLE,
});

export const USER_INTERFACE_ADD_STEP_OF_TYPE =
  'USER_INTERFACE_ADD_STEP_OF_TYPE';
export const updateAddStepOfType = ({
  layoutTrait,
  presentation,
  stepType,
}) => ({
  type: USER_INTERFACE_ADD_STEP_OF_TYPE,
  payload: {
    layoutTrait,
    presentation,
    stepType,
  },
});
