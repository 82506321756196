import { TOOLTIP } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, TOOLTIP_ICONIC } from './defaults';

export default {
  id: TOOLTIP_ICONIC,
  name: 'Iconic',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: TOOLTIP,
  isDefault: true,
  content: {
    id: '6db79ff0-e099-453e-a209-dd7ad28efa18',
    type: 'group',
    children: [
      {
        id: '9e71816a-f0a9-4c9d-834d-c3bae5688b57',
        type: 'tooltip',
        content: {
          id: '42471171-2936-440e-a8a0-64b3edf5384a',
          items: [
            {
              distribution: 'equal',
              id: '8e2926f9-a8a6-4ae9-8381-cb072971ee27',
              items: [
                {
                  blockType: 'emoji',
                  content: {
                    id: 'ab2313ca-ea83-4870-8bf4-1c9fe1d62597',
                    style: {
                      fontSize: 32,
                      horizontalAlignment: 'center',
                      marginTop: 24,
                      textAlignment: 'center',
                    },
                    text: '❤️',
                    type: 'text',
                  },
                  id: '66e71fb9-3d43-402d-bfc9-a5f35fb0d46f',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: 'd5539220-f00f-46c5-b5e0-01feb4f39aa7',
              items: [
                {
                  blockType: 'text',
                  content: {
                    id: 'ca226237-d099-47a6-9057-c400de8194a2',
                    spans: [
                      {
                        text: 'Track your favorites',
                      },
                    ],
                    style: {
                      fontName: 'System Default Bold',
                      fontSize: 15,
                      foregroundColor: {
                        light: '#000000',
                      },
                      horizontalAlignment: 'center',
                      marginBottom: 8,
                      marginLeading: 16,
                      marginTop: 8,
                      marginTrailing: 16,
                      textAlignment: 'center',
                    },
                    text: 'Track your favorites',
                    type: 'text',
                  },
                  id: 'ed01e57a-c436-4f42-acc4-2c800cac0ede',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '64a7e30a-d3f6-4725-a4bf-d090b1807de3',
              items: [
                {
                  blockType: 'text',
                  content: {
                    id: '2eba9e98-df12-48ce-9de2-9ca12dd00cf4',
                    spans: [
                      {
                        text: 'Anything you favorite will be stored here for safekeeping.',
                      },
                    ],
                    style: {
                      fontSize: 15,
                      foregroundColor: {
                        light: '#000000',
                      },
                      horizontalAlignment: 'center',
                      marginBottom: 8,
                      marginLeading: 16,
                      marginTop: 8,
                      marginTrailing: 16,
                      textAlignment: 'center',
                    },
                    text: 'Anything you favorite will be stored here for safekeeping.',
                    type: 'text',
                  },
                  id: 'e81b1978-f630-4b60-8c53-eba42c092907',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: 'e082d485-7c5d-4995-baee-e6bd8c457687',
              items: [
                {
                  blockType: 'button',
                  content: {
                    content: {
                      id: 'b07ece16-24e7-491a-9cb3-6ac2daa45460',
                      spans: [
                        {
                          style: {
                            fontSize: 15,
                          },
                          text: 'Next',
                        },
                      ],
                      style: {
                        fontSize: 15,
                        foregroundColor: {
                          light: '#FFFFFF',
                        },
                      },
                      text: 'Next',
                      type: 'text',
                    },
                    id: '29c6c1c2-20ef-43a9-b19b-2aee5e87b8cf',
                    style: {
                      backgroundColor: {
                        light: '#5C5CFF',
                      },
                      cornerRadius: 6,
                      horizontalAlignment: 'trailing',
                      marginBottom: 16,
                      marginLeading: 16,
                      marginTop: 8,
                      marginTrailing: 16,
                      paddingBottom: 10,
                      paddingLeading: 15,
                      paddingTop: 10,
                      paddingTrailing: 15,
                    },
                    type: 'button',
                  },
                  id: '2efea1f8-6a51-4a32-85e6-9d19cbebe2cc',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {
          '29c6c1c2-20ef-43a9-b19b-2aee5e87b8cf': [
            {
              config: {},
              on: 'tap',
              type: '@appcues/continue',
            },
          ],
        },
        traits: [
          {
            config: {
              contentDistanceFromTarget: 20,
              contentPreferredPosition: 'top',
              height: 50,
              relativeX: 0.5,
              relativeY: 0.5,
              width: 50,
              x: -25,
              y: -25,
            },
            type: '@appcues/target-rectangle',
          },
          {
            config: {
              blurRadius: 50,
              shape: 'circle',
            },
            type: '@appcues/backdrop-keyhole',
          },
          {
            config: {
              actions: [
                {
                  on: 'tap',
                  type: '@appcues/no-op',
                },
              ],
            },
            type: '@appcues/target-interaction',
          },
        ],
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          pointerBase: 24,
          pointerLength: 12,
          presentationStyle: 'floating',
          style: {
            backgroundColor: {
              light: '#FFFFFF',
            },
            cornerRadius: 8,
            shadow: {
              color: {
                light: '#00000029',
              },
              radius: 6,
              x: 0,
              y: 3,
            },
            width: 260,
          },
        },
        type: '@appcues/tooltip',
      },
      {
        config: {
          buttonAppearance: 'minimal',
        },
        type: '@appcues/skippable',
      },
      {
        config: {
          backgroundColor: {
            light: '#0000004D',
          },
        },
        type: '@appcues/backdrop',
      },
      {
        type: '@appcues/step-transition-animation',
      },
    ],
  },
};
