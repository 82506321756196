import React from 'react';
import PropTypes from 'prop-types';
import { MODAL, TOOLTIP, EMBED, LAYOUT_TRAITS } from 'lib/trait';
import {
  STANDARD,
  HALF_SCREEN,
  FULL_SCREEN,
  COVER_SHEET,
  SLIDEOUT,
  FLOATING,
  ANCHORED,
  PRESENTATIONS,
  PRESENTATIONS_TYPES,
} from 'lib/presentation';

const StandardIcon = (
  <>
    <rect width="32" height="61" rx="4" fill="#394455" />
    <path stroke="#E7ECF3" d="M12 4h8M12 56h8" />
    <path fill="#B0B6CF" d="M2 8h28v44H2z" />
    <rect x="4" y="18" width="24" height="24" rx="2" fill="#fff" />
    <rect x="10" y="33" width="12" height="4" rx="2" fill="#D7D7FF" />
    <rect x="7" y="28" width="18" height="2" rx="1" fill="#D7D7FF" />
    <rect x="7" y="23" width="18" height="2" rx="1" fill="#D7D7FF" />
  </>
);

const HalfScreenIcon = (
  <>
    <rect width="32" height="61" rx="4" fill="#394455" />
    <path stroke="#E7ECF3" d="M12 4h8M12 56h8" />
    <path fill="#B0B6CF" d="M2 8h28v44H2z" />
    <path fill="#fff" d="M2 30h28v22H2z" />
    <rect x="9" y="44" width="14" height="4" rx="2" fill="#D7D7FF" />
    <rect x="6" y="39" width="20" height="2" rx="1" fill="#D7D7FF" />
    <rect x="6" y="34" width="20" height="2" rx="1" fill="#D7D7FF" />
  </>
);

const FullScreenIcon = (
  <>
    <rect width="32" height="61" rx="4" fill="#394455" />
    <path stroke="#E7ECF3" d="M12 4h8M12 56h8" />
    <path fill="#fff" d="M2 8h28v44H2z" />
    <rect x="9" y="35" width="14" height="4" rx="2" fill="#D7D7FF" />
    <rect x="6" y="28" width="20" height="2" rx="1" fill="#D7D7FF" />
    <rect x="6" y="22" width="20" height="2" rx="1" fill="#D7D7FF" />
  </>
);

const CoverSheetIcon = (
  <>
    <rect width="32" height="61" rx="4" fill="#394455" />
    <path stroke="#E7ECF3" d="M12 4h8M12 56h8" />
    <path fill="#B0B6CF" d="M2 15h28V8H2z" />
    <path d="M2 15a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3v37H2V15Z" fill="#fff" />
    <rect x="9" y="35" width="14" height="4" rx="2" fill="#D7D7FF" />
    <rect x="6" y="28" width="20" height="2" rx="1" fill="#D7D7FF" />
    <rect x="6" y="22" width="20" height="2" rx="1" fill="#D7D7FF" />
  </>
);

const SlideoutIcon = (
  <>
    <rect y=".5" width="32" height="61" rx="4" fill="#394455" />
    <path
      stroke="#E7ECF3"
      strokeWidth="2"
      strokeLinecap="round"
      d="M12 4.5h8M12 56.5h8"
    />
    <path fill="#B0B6CF" d="M2 8.5h28v44H2z" />
    <rect x="4.5" y="32.5" width="17" height="17" rx="2" fill="#fff" />
    <rect
      x="8.75"
      y="43.125"
      width="8.5"
      height="2.833"
      rx="1.417"
      fill="#D7D7FF"
    />
    <rect
      x="6.625"
      y="39.583"
      width="12.75"
      height="1.417"
      rx=".708"
      fill="#D7D7FF"
    />
    <rect
      x="6.625"
      y="36.042"
      width="12.75"
      height="1.417"
      rx=".708"
      fill="#D7D7FF"
    />
  </>
);

const FloatingIcon = (
  <>
    <rect y=".5" width="32" height="61" rx="4" fill="#394455" />
    <path stroke="#E7ECF3" d="M12 4.5h8M12 56.5h8" />
    <path fill="#B0B6CF" d="M2 8.5h28v44H2z" />
    <rect x="6" y="15.5" width="20" height="20" rx="2" fill="#fff" />
    <rect x="11" y="28.5" width="10" height="4" rx="2" fill="#D7D7FF" />
    <rect x="9" y="23.5" width="14" height="2" rx="1" fill="#D7D7FF" />
    <rect x="9" y="18.5" width="14" height="2" rx="1" fill="#D7D7FF" />
    <path d="m16 38.5-1.732-3h3.464L16 38.5ZM15.5 41.5h1v4h-1z" fill="#fff" />
    <path fill="#fff" d="M18 43v1h-4v-1z" />
  </>
);

const AnchoredIcon = (
  <>
    <rect width="32" height="61" rx="4" fill="#394455" />
    <path stroke="#E7ECF3" d="M12 4h8m-8 52h8" />
    <path fill="#B0B6CF" d="M2 8h28v44H2z" />
    <rect x="6" y="15" width="20" height="20" rx="2" fill="#fff" />
    <path
      d="M17.56 23.055a.777.777 0 1 1 0 1.556h-.779v5.058h1.167a2.335 2.335 0 0 0 2.335-2.335v-.148l-.192.15c-.207.249-.577.249-.805 0-.226-.206-.226-.575 0-.804l1.361-1.362c.229-.226.599-.226.805 0l1.362 1.362c.248.229.248.598 0 .805-.207.248-.576.248-.805 0l-.17-.15v.147a3.89 3.89 0 0 1-3.89 3.891h-3.891a3.89 3.89 0 0 1-3.89-3.89v-.149l-.172.15a.55.55 0 0 1-.825 0c-.228-.206-.228-.575 0-.804l1.362-1.362a.587.587 0 0 1 .825 0l1.34 1.362c.248.229.248.598 0 .805-.206.248-.576.248-.804 0l-.17-.15v.147a2.321 2.321 0 0 0 2.334 2.335h1.167V24.61h-.778a.777.777 0 1 1 0-1.556h.265a2.33 2.33 0 0 1-1.043-1.945 2.32 2.32 0 0 1 2.334-2.335 2.335 2.335 0 0 1 2.334 2.335c0 .812-.435 1.527-1.043 1.945h.265Zm-1.557-1.167a.777.777 0 1 0 .002-1.555.777.777 0 0 0-.002 1.555Z"
      fill="#B0B6CF"
    />
    <path d="m16 38-1.732-3h3.464L16 38Z" fill="#fff" />
  </>
);

const ICONS = {
  [MODAL]: {
    [STANDARD]: StandardIcon,
    [HALF_SCREEN]: HalfScreenIcon,
    [FULL_SCREEN]: FullScreenIcon,
    [COVER_SHEET]: CoverSheetIcon,
    [SLIDEOUT]: SlideoutIcon,
  },
  [TOOLTIP]: {
    [FLOATING]: FloatingIcon,
    [ANCHORED]: AnchoredIcon,
  },
  [EMBED]: {},
};

const PresentationIcon = ({ layoutTrait, presentation }) => {
  const embedSizes = {
    viewbox: '0 0 56 56',
    width: '56',
    height: '56',
  };

  const defaultSizes = {
    viewbox: '0 0 32 61',
    width: '32',
    height: '61',
  };

  const { viewBox, width, height } =
    layoutTrait === EMBED ? embedSizes : defaultSizes;

  return (
    <svg
      aria-label={PRESENTATIONS[layoutTrait][presentation]}
      role="img"
      fill="none"
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      {ICONS[layoutTrait][presentation]}
    </svg>
  );
};

export default PresentationIcon;

PresentationIcon.propTypes = {
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  presentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
};
