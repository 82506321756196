import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MODAL, TOOLTIP, EMBED, LAYOUT_TRAITS } from 'lib/trait';
import {
  STANDARD,
  HALF_SCREEN,
  FULL_SCREEN,
  COVER_SHEET,
  SLIDEOUT,
  FLOATING,
  ANCHORED,
  EMBED_PRESENTATION,
  PRESENTATIONS,
  PRESENTATIONS_TYPES,
} from 'lib/presentation';

const FullScreenIcon = (
  <>
    <rect
      x=".833"
      y=".838"
      width="12.333"
      height="20.333"
      rx="1.5"
      fill="none"
      stroke="#fff"
    />
    <rect x="3" y="13.67" width="8" height="2.667" rx="1.333" fill="#fff" />
    <rect x="3" y="9.67" width="8" height="1.333" rx=".667" fill="#fff" />
    <rect x="3" y="7.004" width="8" height="1.333" rx=".667" fill="#fff" />
  </>
);

const HalfSheetIcon = (
  <>
    <rect
      x=".833"
      y="1.428"
      width="12.333"
      height="20.333"
      rx="1.5"
      fill="none"
      stroke="#fff"
    />
    <rect x="3" y="16.928" width="8" height="2.667" rx="1.333" fill="#fff" />
    <rect x="3" y="12.928" width="8" height="1.333" rx=".667" fill="#fff" />
    <rect x="3" y="10.26" width="8" height="1.333" rx=".667" fill="#fff" />
    <path fill="#fff" d="M1 1.594h12v7H1z" />
  </>
);

const StandardIcon = (
  <path
    d="M.333 2.519a2 2 0 0 1 2-2h9.334a2 2 0 0 1 2 2v17.333a2 2 0 0 1-2 2H2.333a2 2 0 0 1-2-2V2.52Zm2.334 3.333a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8.667a1 1 0 0 0 1-1v-10a1 1 0 0 0-1-1H2.667Zm1.666 8a1.333 1.333 0 1 0 0 2.666h5.334a1.333 1.333 0 1 0 0-2.666H4.333ZM3 10.518c0-.368.298-.666.667-.666h6.666a.667.667 0 1 1 0 1.333H3.667A.667.667 0 0 1 3 10.518Zm.667-3.332a.667.667 0 1 0 0 1.333h6.666a.667.667 0 1 0 0-1.333H3.667Z"
    fill="#fff"
  />
);

const SlideoutIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 1.867C0 .835.836 0 1.867 0h10.266C13.165 0 14 .835 14 1.867v18.666a1.867 1.867 0 0 1-1.867 1.867H1.867A1.867 1.867 0 0 1 0 20.533V1.867ZM2.333 8.4a.933.933 0 0 0-.933.933v8.4c0 .516.418.934.933.934H9.8a.934.934 0 0 0 .933-.934v-8.4A.933.933 0 0 0 9.8 8.4H2.333Zm.934 6.533a.934.934 0 1 0 0 1.867h5.6a.933.933 0 0 0 0-1.867h-5.6ZM2.333 12.6c0-.258.209-.467.467-.467h6.533a.467.467 0 1 1 0 .934H2.8a.467.467 0 0 1-.467-.467Zm.467-2.333a.467.467 0 1 0 0 .933h6.533a.467.467 0 1 0 0-.933H2.8Z"
    fill="#fff"
  />
);

const CoverSheetIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.667 4H1.333v15.667a1 1 0 0 0 1 1h9.334a1 1 0 0 0 1-1V4ZM2.333.333a2 2 0 0 0-2 2v17.334a2 2 0 0 0 2 2h9.334a2 2 0 0 0 2-2V2.333a2 2 0 0 0-2-2H2.333ZM3 15c0-.736.597-1.333 1.333-1.333h5.334a1.333 1.333 0 0 1 0 2.667H4.333A1.333 1.333 0 0 1 3 14.999Zm.667-5.333a.667.667 0 1 0 0 1.333h6.666a.667.667 0 1 0 0-1.333H3.667Zm-.667-2C3 7.299 3.298 7 3.667 7h6.666a.667.667 0 1 1 0 1.333H3.667A.667.667 0 0 1 3 7.667Z"
    fill="white"
  />
);

const FloatingIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.0274658 5C0.0274658 3.89543 0.922896 3 2.02747 3H11.9725C13.0771 3 13.9725 3.89543 13.9725 5V14.9451C13.9725 16.0496 13.0771 16.9451 11.9725 16.9451H8.18634L6.99993 19L5.81352 16.9451H2.02747C0.922898 16.9451 0.0274658 16.0496 0.0274658 14.9451V5ZM3.51374 13.4592C3.51374 12.689 4.13808 12.0646 4.90824 12.0646H9.09177C9.86193 12.0646 10.4863 12.689 10.4863 13.4592C10.4863 14.2293 9.86193 14.8537 9.09177 14.8537H4.90825C4.13808 14.8537 3.51374 14.2293 3.51374 13.4592ZM2.81682 8.57837C2.43174 8.57837 2.11957 8.89054 2.11957 9.27563C2.11957 9.66071 2.43174 9.97288 2.81682 9.97288H11.1839C11.569 9.97288 11.8811 9.66071 11.8811 9.27563C11.8811 8.89054 11.569 8.57837 11.1839 8.57837H2.81682ZM2.11957 5.78936C2.11957 5.40427 2.43174 5.0921 2.81682 5.0921H11.1839C11.569 5.0921 11.8811 5.40427 11.8811 5.78936C11.8811 6.17444 11.569 6.48661 11.1839 6.48661H2.81682C2.43174 6.48661 2.11957 6.17444 2.11957 5.78936Z"
    fill="white"
  />
);

const AnchoredIcon = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.0274658 5C0.0274658 3.89543 0.922896 3 2.02747 3H11.9725C13.0771 3 13.9725 3.89543 13.9725 5V14.9451C13.9725 16.0496 13.0771 16.9451 11.9725 16.9451H8.18634L6.99993 19L5.81352 16.9451H2.02747C0.922898 16.9451 0.0274658 16.0496 0.0274658 14.9451V5ZM8.33652 8.33261C8.70543 8.33261 9.00347 8.63066 9.00347 8.99957C9.00347 9.36848 8.70543 9.66652 8.33652 9.66652H7.66956V14.0017H8.67C9.77464 14.0017 10.6709 13.1055 10.6709 12.0009V11.8737L10.5062 12.003C10.329 12.2155 10.0122 12.2155 9.81633 12.003C9.62249 11.8258 9.62249 11.509 9.81633 11.3131L10.9835 10.1459C11.1794 9.95206 11.4962 9.95206 11.6734 10.1459L12.8406 11.3131C13.0531 11.509 13.0531 11.8258 12.8406 12.003C12.6634 12.2155 12.3466 12.2155 12.1507 12.003L12.0048 11.8737V12.0009C12.0048 13.8433 10.5125 15.3356 8.67 15.3356H5.33521C3.49275 15.3356 2.00043 13.8433 2.00043 12.0009V11.8737L1.85391 12.003C1.65862 12.2155 1.34181 12.2155 1.1465 12.003C0.951166 11.8258 0.951166 11.509 1.1465 11.3131L2.3137 10.1459C2.50899 9.95206 2.82579 9.95206 3.02109 10.1459L4.17013 11.3131C4.38272 11.509 4.38272 11.8258 4.17013 12.003C3.99297 12.2155 3.67616 12.2155 3.48024 12.003L3.33435 11.8737V12.0009C3.33435 13.1055 4.21181 14.0017 5.33521 14.0017H6.33565V9.66652H5.66869C5.29978 9.66652 5.00174 9.36848 5.00174 8.99957C5.00174 8.63066 5.29978 8.33261 5.66869 8.33261H5.89587C5.35606 7.97412 5.00174 7.36136 5.00174 6.66522C5.00174 5.56016 5.8792 4.66435 7.0026 4.66435C8.10725 4.66435 9.00347 5.56016 9.00347 6.66522C9.00347 7.36136 8.63039 7.97412 8.10934 8.33261H8.33652ZM7.0026 7.33218C7.37152 7.33218 7.66956 7.03413 7.66956 6.66522C7.66956 6.29693 7.37152 5.99826 7.0026 5.99826C6.6337 5.99826 6.33565 6.29693 6.33565 6.66522C6.33565 7.03413 6.6337 7.33218 7.0026 7.33218Z"
    fill="white"
  />
);

const EmbedIcon = (
  <path
    fill="#fff"
    fillRule="evenodd"
    d="M2.5.333a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h9.75a2 2 0 0 0 2-2v-18a2 2 0 0 0-2-2H2.5Zm.719 3.782a1 1 0 0 0-1 1v12.437a1 1 0 0 0 1 1h8.312a1 1 0 0 0 1-1V5.115a1 1 0 0 0-1-1H3.22Zm1.21 3.093c-.651 0-1.179.528-1.179 1.179v5.893c0 .65.528 1.178 1.179 1.178h5.892A1.18 1.18 0 0 0 11.5 14.28V8.387a1.18 1.18 0 0 0-1.179-1.179H4.43Zm3.68 3.127a.442.442 0 0 1 .593-.656l1.474 1.326a.442.442 0 0 1 0 .656l-1.473 1.326a.442.442 0 0 1-.593-.656l1.108-.998-1.108-.998Zm-1.437-.624c.163.182.149.446-.032.624l-1.108.998 1.108.998c.18.162.195.442.032.625-.18.18-.442.195-.625.031l-1.472-1.326a.44.44 0 0 1 0-.655L6.047 9.68c.183-.164.446-.15.625.031Z"
    clipRule="evenodd"
  />
);

const ICONS = {
  [MODAL]: {
    [STANDARD]: StandardIcon,
    [HALF_SCREEN]: HalfSheetIcon,
    [FULL_SCREEN]: FullScreenIcon,
    [COVER_SHEET]: CoverSheetIcon,
    [SLIDEOUT]: SlideoutIcon,
  },
  [TOOLTIP]: {
    [FLOATING]: FloatingIcon,
    [ANCHORED]: AnchoredIcon,
  },
  [EMBED]: {
    [EMBED_PRESENTATION]: EmbedIcon,
  },
};

const StepIcon = ({ layoutTrait, presentation, className }) => (
  <svg
    aria-label={PRESENTATIONS[layoutTrait][presentation]}
    className={className}
    role="img"
    viewBox="0 0 14 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    {ICONS[layoutTrait][presentation]}
  </svg>
);

StepIcon.propTypes = {
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  presentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  className: PropTypes.string,
};

export default styled(StepIcon)``;
