import { TOOLTIP } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, TOOLTIP_CTA } from './defaults';

export default {
  id: TOOLTIP_CTA,
  name: 'Call-to-action',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: TOOLTIP,
  isDefault: true,
  content: {
    id: '299efc53-f27a-44f7-b452-69bc4df6d30c',
    type: 'group',
    children: [
      {
        id: '0bf4b1c6-c7ef-4b7c-981b-b9d8c45f7835',
        type: 'tooltip',
        content: {
          id: 'f0194732-41ee-4244-8168-0a219ada9fe3',
          items: [
            {
              distribution: 'equal',
              id: '36b66814-ffd2-43ad-a059-f37df4c7f620',
              items: [
                {
                  blockType: 'imageWithText',
                  content: {
                    distribution: 'center',
                    id: '55a504d8-e728-44ec-b25c-48055ab2584c',
                    items: [
                      {
                        contentMode: 'fit',
                        id: '1197e569-15af-45db-ae0a-782f988fbc1b',
                        imageUrl:
                          'https://images.appcues.com/v1692992744/106160/mtgk6clvoxhkemneflv4.png',
                        intrinsicSize: {
                          height: 192,
                          width: 240,
                        },
                        style: {
                          marginTrailing: 8,
                          verticalAlignment: 'top',
                          width: 56,
                        },
                        type: 'image',
                      },
                      {
                        id: 'c38572d8-0431-4f9e-a13c-019f5cfab39e',
                        items: [
                          {
                            id: 'ace994f6-a8af-4ef7-8a58-63d71221cd2e',
                            spans: [
                              {
                                style: {
                                  fontSize: 15,
                                  foregroundColor: {
                                    light: '#ffffff',
                                  },
                                },
                                text: "It's more fun with friends!",
                              },
                            ],
                            style: {
                              fontName: 'System Default Semibold',
                              fontSize: 15,
                              foregroundColor: {
                                light: '#ffffff',
                              },
                              horizontalAlignment: 'leading',
                              textAlignment: 'leading',
                            },
                            text: "It's more fun with friends!",
                            type: 'text',
                          },
                          {
                            id: '96d4305d-b5d2-4388-b9ee-1f733606d316',
                            spans: [
                              {
                                style: {
                                  fontSize: 15,
                                  foregroundColor: {
                                    light: '#ffffff',
                                  },
                                },
                                text: 'Invite your friends to the app to begin sharing content.',
                              },
                            ],
                            style: {
                              fontSize: 15,
                              foregroundColor: {
                                light: '#ffffff',
                              },
                              horizontalAlignment: 'leading',
                              marginTop: 0,
                              textAlignment: 'leading',
                            },
                            text: 'Invite your friends to the app to begin sharing content.',
                            type: 'text',
                          },
                        ],
                        orientation: 'vertical',
                        style: {
                          horizontalAlignment: 'leading',
                        },
                        type: 'stack',
                      },
                    ],
                    orientation: 'horizontal',
                    style: {
                      horizontalAlignment: 'leading',
                      marginTop: 8,
                      paddingBottom: 8,
                      paddingLeading: 16,
                      paddingTop: 8,
                      paddingTrailing: 16,
                      verticalAlignment: 'top',
                    },
                    type: 'stack',
                  },
                  id: '16216b89-70de-4082-9027-54f21ae23b7d',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '9d52cde4-b8c9-4ce8-b8da-c845b5cd6e1f',
              items: [
                {
                  blockType: 'button',
                  content: {
                    content: {
                      id: 'a75f1584-d540-4b9a-9c67-c8a6eb7de660',
                      spans: [
                        {
                          style: {
                            fontName: 'System Default Bold',
                            fontSize: 15,
                            foregroundColor: {
                              light: '#165196',
                            },
                          },
                          text: 'Invite now',
                        },
                      ],
                      style: {
                        fontName: 'System Default Bold',
                        fontSize: 15,
                        foregroundColor: {
                          light: '#165196',
                        },
                      },
                      text: 'Invite now',
                      type: 'text',
                    },
                    id: 'ed51d539-ca4a-41ce-850e-c75db31a5154',
                    style: {
                      backgroundColor: {
                        light: '#ffffff',
                      },
                      cornerRadius: 26,
                      horizontalAlignment: 'trailing',
                      marginBottom: 16,
                      marginLeading: 16,
                      marginTop: 8,
                      marginTrailing: 16,
                      paddingBottom: 8,
                      paddingLeading: 16,
                      paddingTop: 8,
                      paddingTrailing: 16,
                    },
                    type: 'button',
                  },
                  id: 'a674afb4-5621-4315-b4d5-b2b43114277b',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {
          'ed51d539-ca4a-41ce-850e-c75db31a5154': [
            {
              config: {},
              on: 'tap',
              type: '@appcues/continue',
            },
          ],
        },
        traits: [
          {
            config: {
              contentDistanceFromTarget: 20,
              contentPreferredPosition: 'top',
              height: 50,
              relativeX: 0.5,
              relativeY: 0.5,
              width: 50,
              x: -25,
              y: -25,
            },
            type: '@appcues/target-rectangle',
          },
          {
            config: {
              blurRadius: 50,
              shape: 'circle',
            },
            type: '@appcues/backdrop-keyhole',
          },
          {
            config: {
              actions: [
                {
                  on: 'tap',
                  type: '@appcues/no-op',
                },
              ],
            },
            type: '@appcues/target-interaction',
          },
        ],
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          pointerBase: 24,
          pointerLength: 12,
          presentationStyle: 'floating',
          style: {
            backgroundColor: {
              light: '#165196',
            },
            cornerRadius: 8,
            shadow: {
              color: {
                light: '#00000029',
              },
              radius: 6,
              x: 0,
              y: 3,
            },
            width: 300,
          },
        },
        type: '@appcues/tooltip',
      },
      {
        config: {
          buttonAppearance: 'minimal',
          buttonStyle: {
            foregroundColor: {
              light: '#ffffff',
            },
            marginBottom: 4,
            marginLeading: 4,
            marginTop: 4,
            marginTrailing: 4,
          },
        },
        type: '@appcues/skippable',
      },
      {
        config: {
          backgroundColor: {
            light: '#0000004D',
          },
        },
        type: '@appcues/backdrop',
      },
      {
        type: '@appcues/step-transition-animation',
      },
    ],
  },
};
