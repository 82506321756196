import React from 'react';
import PropTypes from 'prop-types';
import Portal from 'ext/components/Portal';
import { EditorShape } from 'entities/step-groups';
import { ContentShape } from 'entities/step-children';
import { Shape as TraitsShape } from 'entities/trait';
import { Embed, Device } from 'components/Editor';
import { PreviewPane } from './styled';

const PreviewEditor = ({ presentationStep, isEmbed, visible }) => {
  const PreviewViewportComponent = isEmbed ? Embed : Device;
  const { content, editor, traits } = presentationStep;

  return (
    <Portal>
      <PreviewPane visible={visible}>
        <PreviewViewportComponent
          content={content}
          editor={editor}
          traits={traits}
        />
      </PreviewPane>
    </Portal>
  );
};

PreviewEditor.propTypes = {
  presentationStep: PropTypes.shape({
    content: ContentShape,
    editor: EditorShape,
    traits: TraitsShape,
  }),
  isEmbed: PropTypes.bool,
  visible: PropTypes.bool,
};

export default PreviewEditor;
