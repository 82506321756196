import { MODAL, TOOLTIP, EMBED } from 'lib/trait';

/**
 * Presentation types
 *
 * @constant
 */
export const STANDARD = 'dialog';
export const HALF_SCREEN = 'halfSheet';
export const FULL_SCREEN = 'full';
export const COVER_SHEET = 'sheet';
export const SLIDEOUT = 'slideout';
export const FLOATING = 'floating';
export const ANCHORED = 'anchored';
export const EMBED_PRESENTATION = 'embed';

/**
 * Presentation types list
 *
 * @constant
 */
export const PRESENTATIONS_TYPES = [
  STANDARD,
  HALF_SCREEN,
  FULL_SCREEN,
  COVER_SHEET,
  SLIDEOUT,
  FLOATING,
  ANCHORED,
  EMBED_PRESENTATION,
];

/**
 * Presentation labels
 *
 * @constant
 */
export const PRESENTATIONS = {
  [MODAL]: {
    [STANDARD]: 'Standard',
    [HALF_SCREEN]: 'Half screen',
    [FULL_SCREEN]: 'Full screen',
    [COVER_SHEET]: 'Cover sheet',
    [SLIDEOUT]: 'Slideout',
  },
  [TOOLTIP]: {
    [FLOATING]: 'Floating',
    [ANCHORED]: 'Anchored',
  },
  [EMBED]: {
    [EMBED_PRESENTATION]: 'Embed',
  },
};

export const PRESENTATIONS_GROUP = {
  [MODAL]: 'Carousel',
  [TOOLTIP]: 'Tooltip',
  [EMBED]: 'Embed',
};
