import styled from 'styled-components';
import SideBar from 'ext/components/SideBar';
import { RadioButtonGroup } from 'ext/components/ui';

const StyledSideBar = styled(SideBar)`
  > header {
    z-index: 2;
  }

  > div:last-of-type {
    ${({ messagesHeight }) =>
      messagesHeight && `margin-bottom: ${messagesHeight}px;`}
  }

  ${RadioButtonGroup} {
    height: 42px;
  }

  // Maintain the default sonar border color for inputs
  input {
    border-color: var(--border-input-default);
  }

  // Apply the sonar active state to buttons
  button[aria-pressed='true'] {
    color: var(--foreground-button-secondary-active);
    background: var(--background-button-secondary-active);
  }
`;

export default StyledSideBar;
