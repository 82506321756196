import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { selectExperience } from 'entities/experiences';
import {
  selectUserPreferences,
  updateUserPreferences,
  Shape as UserPreferencesShape,
} from 'entities/user-preferences';
import { selectSelected } from 'entities/selected';
import { selectStepGroup } from 'entities/step-groups';
import { Shape as ScreenShape } from 'entities/screens';
import { EXPERIENCE_TYPES } from 'lib/experiences';
import { PLATFORMS } from 'lib/platform';
import { capitalize } from 'utils/strings-handler';
import TopMenu from './TopMenu';

export function TopMenuManager({
  screen,
  platform,
  type,
  userPreferences,
  onUserPreferencesUpdate,
}) {
  const { track } = useAnalytics();

  const { orientation, theme, direction, viewport, embed, locale } =
    userPreferences;

  const onPreferenceChange = (preference, value) => {
    track('Mobile Builder interaction', {
      name: `Selected User Preference - ${capitalize(preference)}: ${capitalize(
        value?.name ?? value
      )}`,
      component: 'TopMenuManager',
    });

    onUserPreferencesUpdate(preference, value);
  };

  return (
    <TopMenu
      theme={theme}
      direction={direction}
      orientation={orientation}
      screen={screen}
      viewport={viewport}
      platform={platform}
      embed={embed}
      locale={locale}
      experienceType={type}
      onPreferenceChange={onPreferenceChange}
    />
  );
}

TopMenuManager.propTypes = {
  screen: ScreenShape,
  platform: PropTypes.oneOf(PLATFORMS),
  type: PropTypes.oneOf(EXPERIENCE_TYPES),
  userPreferences: UserPreferencesShape,
  onUserPreferencesUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const { platform, type } = selectExperience(state) ?? {};
  const { stepGroup } = selectSelected(state) ?? {};
  const { editor } = selectStepGroup(state, stepGroup) ?? {};
  const { screen } = editor ?? {};
  const userPreferences = selectUserPreferences(state);

  return {
    screen,
    platform,
    type,
    userPreferences,
  };
};

const mapDispatchToProps = {
  onUserPreferencesUpdate: updateUserPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuManager);
