import { put, select, takeEvery } from 'redux-saga/effects';
import {
  REQUEST_RESOLVED,
  ITEM_INSERTED,
  ITEM_UPDATED,
  ITEM_REMOVED,
  CHILD_MOVED,
} from 'ext/lib/collections';
import { TYPE as EXPERIENCE_TYPE } from 'entities/experiences';
import { TYPE as STEP_GROUP_TYPE } from 'entities/step-groups';
import { TYPE as STEP_CHILDREN_TYPE } from 'entities/step-children';
import { TYPE as SELECTED_TYPE, selectSelected } from 'entities/selected';
import { TYPE as BLOCK_TYPE } from 'entities/block';
import { selectHistoryLogEntities } from './reducer';
import { updateCurrent, updateHistoryLog } from './actions';

// We create the current history log state when the experience is loaded for the first time
function* updateCurrentHistoryLog(action) {
  const { meta } = action ?? {};
  if (meta?.type === EXPERIENCE_TYPE) {
    const { experience, stepGroups, stepChildren } = yield select(
      selectHistoryLogEntities
    );
    const selected = yield select(selectSelected);
    yield put(
      updateCurrent({ experience, stepGroups, stepChildren, selected })
    );
  }
}

function* updateEntireHistoryLog(action) {
  const { meta, payload } = action ?? {};
  const allowedTypes = [
    STEP_GROUP_TYPE,
    STEP_CHILDREN_TYPE,
    SELECTED_TYPE,
    BLOCK_TYPE,
  ];

  const isAllowedType = allowedTypes.includes(meta?.type);

  const isExperienceType = meta?.type === EXPERIENCE_TYPE;
  const isUpdateSteps = payload?.isStepsUpdate;
  const isLocalizationUpdate = payload?.isLocalization;
  const isChildMovedAction = action?.type === CHILD_MOVED;
  const isExperienceTypeAllowed =
    isExperienceType &&
    (isUpdateSteps || isLocalizationUpdate || isChildMovedAction);

  if (isAllowedType || isExperienceTypeAllowed) {
    const historyLog = yield select(selectHistoryLogEntities);
    yield put(updateHistoryLog(historyLog));
  }
}

export default function* saga() {
  yield takeEvery([REQUEST_RESOLVED], updateCurrentHistoryLog);
  yield takeEvery(
    [ITEM_INSERTED, ITEM_UPDATED, ITEM_REMOVED, CHILD_MOVED],
    updateEntireHistoryLog
  );
}
