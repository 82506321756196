import keyBy from 'lodash.keyby';
import { createCollectionReducer, DELETED } from 'ext/lib/collections';
import { defaultTemplates } from 'lib/templates';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectTemplates = state => ({
  ...keyBy(defaultTemplates, 'id'),
  ...Object.fromEntries(
    Object.entries(state[TYPE]).filter(([, template]) => !template[DELETED])
  ),
});
