import { createLifecycleFor, createMoveActionsFor } from 'ext/lib/collections';

export const TYPE = 'experience';

export const {
  create,
  focus,
  insert,
  send,
  resolve,
  reject,
  update,
  flush,
  patterns,
} = createLifecycleFor(TYPE);

export const { move: moveStepGroup, patterns: movePatterns } =
  createMoveActionsFor(TYPE);

export const STEPS_UPDATED = '@experiences/STEPS_UPDATED';
export const stepsUpdate = experience => ({
  type: STEPS_UPDATED,
  payload: experience,
  meta: {
    type: TYPE,
  },
});

export const TEMPLATE_APPLIED = '@experiences/TEMPLATE_APPLIED';
export const templateApply = ({
  template,
  mode,
  presentation,
  skipTraits,
}) => ({
  type: TEMPLATE_APPLIED,
  payload: {
    template,
    mode,
    presentation,
    skipTraits,
  },
  meta: {
    type: TYPE,
  },
});
