import {
  TYPE,
  USER_INTERFACE_PREVIEW_UPDATED,
  USER_INTERFACE_TARGET_PLACEMENT_UPDATED,
  USER_INTERFACE_SIDE_BAR_TAB_UPDATED,
  USER_INTERFACE_FLOATING_SCREEN_TOGGLE,
  USER_INTERFACE_ADD_STEP_OF_TYPE,
} from './actions';

const initialState = {
  preview: {},
  targetPlacement: false,
  selectedSideBarTab: null,
  floatingScreen: false,
  addStepOfType: {},
};

export default function userInterface(state = initialState, action) {
  const { type, payload } = action ?? {};
  const {
    blockId,
    previewType,
    show,
    stepGroup,
    tab,
    layoutTrait,
    presentation,
    stepType,
  } = payload ?? {};
  const blockPreview = state.preview[blockId] ?? [];

  switch (type) {
    case USER_INTERFACE_PREVIEW_UPDATED:
      return {
        ...state,
        preview: {
          ...state.preview,
          [blockId]: [
            ...blockPreview.filter(preview => preview.type !== previewType),
            {
              type: previewType,
              show,
            },
          ],
        },
      };
    case USER_INTERFACE_TARGET_PLACEMENT_UPDATED:
      return {
        ...state,
        targetPlacement: !state.targetPlacement,
      };
    case USER_INTERFACE_SIDE_BAR_TAB_UPDATED:
      return {
        ...state,
        selectedSideBarTab: { [stepGroup]: tab },
      };

    case USER_INTERFACE_FLOATING_SCREEN_TOGGLE:
      return {
        ...state,
        floatingScreen: !state.floatingScreen,
      };
    case USER_INTERFACE_ADD_STEP_OF_TYPE:
      return {
        ...state,
        addStepOfType: {
          layoutTrait,
          presentation,
          stepType,
        },
      };
    default:
      return state;
  }
}

export const selectUserInterface = state => state[TYPE];

export const selectPreview = (state, blockId) => {
  const blockPreview = selectUserInterface(state).preview[blockId] ?? [];
  return blockPreview.reduce(
    (acc, { type, show }) => ({ ...acc, [type]: show }),
    {}
  );
};

export const selectPreviewType = (state, { blockId, previewType }) => {
  const selectedPreview = selectUserInterface(state).preview[blockId] ?? [];
  return selectedPreview.find(({ type }) => type === previewType) ?? {};
};

export const selectSideBarTab = (state, stepGroup) => {
  return selectUserInterface(state).selectedSideBarTab?.[stepGroup] ?? null;
};

export const selectFloatingScreen = state => {
  return selectUserInterface(state).floatingScreen;
};

export const selectAddStepOfType = state => {
  return selectUserInterface(state).addStepOfType;
};
