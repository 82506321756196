import React, { Fragment, forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Input, Text } from '@appcues/sonar';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import { Shape as TemplateShape } from 'entities/templates';
import { LAYOUT_TRAITS } from 'lib/trait';
import { PRESENTATIONS_TYPES } from 'lib/presentation';
import {
  GRID_LOAD_MODAL,
  LIST_NEW_STEP,
  TEMPLATE_CONTEXTS,
} from 'lib/templates';
import CreatedBy from './CreatedBy';
import Screenshot from './Screenshot';
import {
  ActionsGroup,
  Wrapper,
  TemplateWrapper,
  TemplateCard,
  Label,
  DropdownMenu,
  DropdownMenuTriggerButton,
  ActionsWrapper,
  TemplateNameInput,
  TemplatesWrapperLabel,
} from './styled';

export const TemplateList = forwardRef(
  (
    {
      templates,
      context,
      emptyTrait,
      emptyPresentation,
      handlePreview,
      handleClick,
      handleTemplateUpdate,
      handleTemplateRemove,
      handleCancel,
    },
    ref
  ) => {
    const $dropdownMenu = useRef(null);

    const isGrid = context === GRID_LOAD_MODAL;

    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(null);
    const [renamingTemplate, setRenamingTemplate] = useState(null);
    const [deletingTemplate, setDeletingTemplate] = useState(null);
    const [searchFilter, setSearchFilter] = useState(null);

    const filteredTemplates = templates
      .filter(({ name, userCreatedBy }) => {
        if (!searchFilter) return true;

        const createdBy = userCreatedBy?.fullName;
        return [name, createdBy].some(property =>
          property?.toLowerCase().includes(searchFilter.toLowerCase())
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    const onClean = event => {
      setRenamingTemplate(null);
      setDeletingTemplate(null);
      setSearchFilter(null);
      event?.stopPropagation();
    };

    useClickOutside([$dropdownMenu], () => setDropdownMenuOpen(null));

    const handleTemplateRename = ({ event, id, name }) => {
      setRenamingTemplate({ id, name });
      setDropdownMenuOpen(null);
      event.stopPropagation();
    };

    const handleTemplateRenaming = (id, name) => {
      setRenamingTemplate({ id, name });
    };

    const onConfirmRename = async (event, id, name) => {
      handleTemplateUpdate({ id, name });
      onClean(event);
    };

    const handleActions = onConfirm => (
      <ActionsWrapper>
        <Button variant="tertiary" onClick={event => onConfirm(event)}>
          <Icon icon={faCheck} />
        </Button>
        <Button variant="tertiary" onClick={event => onClean(event)}>
          <Icon icon={faXmark} />
        </Button>
      </ActionsWrapper>
    );

    const handleTemplateNameChange = (id, name) => (
      <>
        <TemplateNameInput
          value={name}
          onClick={event => event.stopPropagation()}
          onChange={event => handleTemplateRenaming(id, event.target.value)}
          onKeyDown={event =>
            event.key === 'Enter' && onConfirmRename(event, id, name)
          }
        />
        {handleActions(event => onConfirmRename(event, id, name))}
      </>
    );

    const onConfirmDelete = async (event, id) => {
      handleTemplateRemove(id);
      onClean(event);
    };

    const handleTemplateDeletion = id => (
      <>
        <Text>Permanently delete?</Text>
        {handleActions(event => onConfirmDelete(event, id))}
      </>
    );

    const handleTemplateDelete = (event, id) => {
      setDropdownMenuOpen(null);
      setDeletingTemplate(id);
      event.stopPropagation();
    };

    const handleTemplateMenu = (id, name) => (
      <DropdownMenu.Root open={dropdownMenuOpen === id} modal>
        <DropdownMenu.Trigger asChild>
          <DropdownMenuTriggerButton
            iconOnly
            variant="tertiary"
            title="Template options"
            onClick={event => {
              setDropdownMenuOpen(id);
              event.stopPropagation();
            }}
          >
            <Icon icon={faEllipsisVertical} />
          </DropdownMenuTriggerButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content ref={$dropdownMenu} side="bottom">
            <DropdownMenu.Item
              onClick={event => handleTemplateRename({ event, id, name })}
            >
              <Icon icon={faPenToSquare} /> Rename
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={event => handleTemplateDelete(event, id)}
            >
              <Icon icon={faTrashCan} /> Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );

    const handleTemplateContent = ({
      id,
      name,
      userCreatedBy,
      createdAt,
      updatedAt,
      isDefault,
    }) => {
      if (renamingTemplate?.id === id) {
        const { name: rename = '' } = renamingTemplate ?? {};
        return handleTemplateNameChange(id, rename);
      }

      if (deletingTemplate === id) {
        return handleTemplateDeletion(id);
      }

      const { avatarUrl, fullName } = userCreatedBy ?? {};
      const showTemplateMenu = !isDefault && isGrid;

      return (
        <>
          <TemplateCard.Info>
            <Label>{name}</Label>
            <CreatedBy
              avatarUrl={avatarUrl}
              fullName={fullName}
              createdAt={createdAt}
              updatedAt={updatedAt}
            />
          </TemplateCard.Info>
          {showTemplateMenu && (
            <TemplateCard.Actions>
              {handleTemplateMenu(id, name)}
            </TemplateCard.Actions>
          )}
        </>
      );
    };

    return (
      <>
        <Wrapper ref={ref} isGrid={isGrid}>
          <Input
            icon={faMagnifyingGlass}
            placeholder="Search by template name..."
            onChange={event => setSearchFilter(event.target.value)}
          />
          {emptyPresentation && (
            <Button
              variant="secondary"
              onClick={() =>
                handleClick({
                  layoutTrait: emptyTrait,
                  presentation: emptyPresentation,
                })
              }
            >
              Start from scratch
            </Button>
          )}
          <TemplateWrapper isGrid={isGrid}>
            {[
              { label: 'Custom Templates', isDefault: false },
              { label: 'Appcues Templates', isDefault: true },
            ].map(({ label, isDefault }) => {
              const templatesInCategory = filteredTemplates.filter(
                template => (template.isDefault ?? false) === isDefault
              );

              if (templatesInCategory.length === 0) return null;

              return (
                <Fragment key={label}>
                  <TemplatesWrapperLabel isGrid={isGrid}>
                    {label}
                  </TemplatesWrapperLabel>
                  {templatesInCategory.map(
                    ({
                      id,
                      name,
                      content,
                      userCreatedBy,
                      createdAt,
                      updatedAt,
                      isDefault: templateIsDefault,
                    }) => (
                      <TemplateCard.Root
                        key={id}
                        onClick={() => handleClick({ content })}
                        onMouseEnter={() => handlePreview?.({ content })}
                        onMouseLeave={() => handlePreview?.()}
                        isGrid={isGrid}
                      >
                        <TemplateCard.ImageContainer isGrid={isGrid}>
                          <Screenshot id={id} version={updatedAt} />
                        </TemplateCard.ImageContainer>
                        <TemplateCard.Footer>
                          {handleTemplateContent({
                            id,
                            name,
                            userCreatedBy,
                            createdAt,
                            updatedAt,
                            isDefault: templateIsDefault ?? false,
                          })}
                        </TemplateCard.Footer>
                      </TemplateCard.Root>
                    )
                  )}
                </Fragment>
              );
            })}
          </TemplateWrapper>
        </Wrapper>
        {context === LIST_NEW_STEP && (
          <ActionsGroup>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </ActionsGroup>
        )}
      </>
    );
  }
);

TemplateList.propTypes = {
  templates: PropTypes.arrayOf(TemplateShape),
  context: PropTypes.oneOf(TEMPLATE_CONTEXTS),
  emptyTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  emptyPresentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  handlePreview: PropTypes.func,
  handleClick: PropTypes.func,
  handleTemplateUpdate: PropTypes.func,
  handleTemplateRemove: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default TemplateList;
