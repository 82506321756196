import React from 'react';
import PropTypes from 'prop-types';
import { Button, DropdownMenu, Icon, Modal } from '@appcues/sonar';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faFileHeart } from '@fortawesome/pro-solid-svg-icons/faFileHeart';
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import useToggle from 'ext/lib/hooks/use-toggle';
import { Shape as TemplateShape } from 'entities/templates';
import { GRID_LOAD_MODAL } from 'lib/templates';
import { TemplateList } from 'components/TemplateList';
import SaveTemplateModal from './SaveTemplateModal';

export function HeaderTemplateMenu({
  templates,
  handleApplyTemplate,
  handleTemplateSave,
  handleTemplateUpdate,
  handleTemplateRemove,
}) {
  const [openTemplateDropdown, toggleOpenTemplateDropdown] = useToggle();
  const [isTemplateModalOpen, toggleTemplateModalOpen] = useToggle();
  const [isSaveTemplateModalOpen, toggleSaveTemplateModalOpen] = useToggle();

  const handleSaveTemplate = () => {
    toggleOpenTemplateDropdown();
    toggleSaveTemplateModalOpen();
  };

  const handleLoadTemplate = () => {
    toggleOpenTemplateDropdown();
    toggleTemplateModalOpen();
  };

  const handleClick = ({ content }) => {
    handleApplyTemplate(content);
    toggleTemplateModalOpen();
  };

  return (
    <>
      <DropdownMenu.Root open={openTemplateDropdown}>
        <DropdownMenu.Trigger asChild onClick={toggleOpenTemplateDropdown}>
          <Button iconOnly variant="tertiary" aria-label="Templates">
            <Icon icon={faEllipsisVertical} />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            onInteractOutside={toggleOpenTemplateDropdown}
          >
            <DropdownMenu.Item onClick={handleSaveTemplate}>
              <Icon icon={faFilePlus} />
              Save step as template
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={handleLoadTemplate}
              disabled={handleApplyTemplate === undefined}
            >
              <Icon icon={faFileHeart} />
              Load template
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <Modal.Root
        open={isTemplateModalOpen}
        onOpenChange={toggleTemplateModalOpen}
        size="large"
      >
        <Modal.Title>Load a template</Modal.Title>
        <TemplateList
          templates={templates}
          context={GRID_LOAD_MODAL}
          handleClick={handleClick}
          handleTemplateUpdate={handleTemplateUpdate}
          handleTemplateRemove={handleTemplateRemove}
        />
        <Modal.Close aria-label="Close">
          <Icon size="large" icon={faXmark} />
        </Modal.Close>
      </Modal.Root>

      <SaveTemplateModal
        templates={templates}
        isModalOpen={isSaveTemplateModalOpen}
        toggleModal={toggleSaveTemplateModalOpen}
        handleTemplateCreate={handleTemplateSave}
        handleTemplateUpdate={handleTemplateUpdate}
      />
    </>
  );
}

HeaderTemplateMenu.propTypes = {
  templates: PropTypes.arrayOf(TemplateShape),
  handleApplyTemplate: PropTypes.func,
  handleTemplateSave: PropTypes.func,
  handleTemplateUpdate: PropTypes.func,
  handleTemplateRemove: PropTypes.func,
};
