import { transform } from 'entities/step-groups';
import { mapTraits, mapStepChild } from './mappers';
/**
 * Generate canned sample presentation step based on layout
 * trait and presentation type to be displayed on preview on
 * the creation of the flow first step
 *
 * @param {string} content - JSON step content
 * @return {object} Editor and content samples
 */
export const generatePreviewStep = ({
  content: stepTemplate,
  presentation,
}) => {
  const stepTemplateWithPresentation = {
    ...stepTemplate,
    traits: mapTraits({ templateTraits: stepTemplate.traits, presentation }),
    children: [
      mapStepChild({
        step: stepTemplate.children[0],
        presentation,
      }),
    ],
  };

  const { stepGroups, stepChildren } = transform(stepTemplateWithPresentation);

  const groupId = Object.keys(stepGroups)[0];
  const childId = Object.keys(stepChildren)[0];
  const { editor } = stepGroups?.[groupId] ?? {};
  const { content, traits } = stepChildren?.[childId] ?? {};

  return { editor, content, traits };
};
