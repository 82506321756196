import { SKIPPABLE } from 'lib/trait';

export const skippableTemplate = buttonAppearance => {
  return {
    type: SKIPPABLE,
    ...(buttonAppearance && {
      config: {
        buttonAppearance,
      },
    }),
  };
};
