import embedAnnouncementImage from './embed-announcement.png';
import embedCtaImage from './embed-cta.png';
import embedPromotionImage from './embed-promotion.png';
import embedSurveyImage from './embed-survey.png';

import tooltipCtaImage from './tooltip-cta.png';
import tooltipIconicImage from './tooltip-iconic.png';

import modalChangelogImage from './modal-changelog.png';
import modalSurveyImage from './modal-survey.png';

export const CONTENT_TEMPLATE_TYPE = 'mobile_step';

export const EMBED_ANNOUNCEMENT = 'embed-announcement';
export const EMBED_PROMOTION = 'embed-promotion';
export const EMBED_SURVEY = 'embed-survey';
export const EMBED_CTA = 'embed-cta';

export const TOOLTIP_ICONIC = 'tooltip-iconic';
export const TOOLTIP_CTA = 'tooltip-cta';

export const MODAL_CHANGELOG = 'modal-changelog';
export const MODAL_SURVEY = 'modal-survey';

export const DEFAULT_TEMPLATE_IMAGES = {
  [EMBED_ANNOUNCEMENT]: embedAnnouncementImage,
  [EMBED_CTA]: embedCtaImage,
  [EMBED_PROMOTION]: embedPromotionImage,
  [EMBED_SURVEY]: embedSurveyImage,
  [TOOLTIP_CTA]: tooltipCtaImage,
  [TOOLTIP_ICONIC]: tooltipIconicImage,
  [MODAL_CHANGELOG]: modalChangelogImage,
  [MODAL_SURVEY]: modalSurveyImage,
};
