import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  handleTargetAreaShape,
  targetAreaCrosshairPosition,
} from 'lib/target-area-shape-handler';
import { ScreenInsetsShape } from 'entities/screens';
import { TargetAreaShape } from 'entities/step-children';
import { toggleFloatingScreen } from 'entities/user-interface';
import {
  Backdrop,
  TargetPositionWrapper,
  TargetPositionCrossButton,
  CrossIcon,
  SelectScreenButton,
} from './styled';

const SHAPES = {
  CIRCLE: 'circle',
  RECTANGLE: 'rectangle',
};

const TRANSPARENT_COLOR = '#00000000';

export function BackdropKeyhole({
  backdrop,
  backdropKeyhole,
  deviceSize,
  targetArea,
  insets,
  isAnchored,
  isFloating,
  isImageService,
  hasScreen,
  hasSelector,
  hasSelectors,
  onTargetPlacementChange,
  onToggleFloatingScreen,
}) {
  const svgId = crypto.randomUUID();
  const { width: deviceWidth, height: deviceHeight } = deviceSize;
  const { backgroundColor = TRANSPARENT_COLOR } = backdrop;

  const {
    shape,
    blurRadius = 0,
    cornerRadius = 0,
    spreadRadius = 0,
  } = backdropKeyhole?.config || {};

  const { xOrigin, yOrigin, xCenter, yCenter, width, height } =
    handleTargetAreaShape({
      shape,
      targetArea,
      spreadRadius,
      deviceSize,
      insets,
      isAnchored,
    });

  const crossHairPosition = targetAreaCrosshairPosition({
    shape,
    isAnchored,
    hasScreen,
    hasSelector,
    deviceSize,
    xCenter,
    yCenter,
    spreadRadius,
  });

  // A circle that fully encompasses the target rectangle.
  const radius = Math.sqrt(width ** 2 + height ** 2) / 2;

  // Subtract a tiny value to preserve stop monotonicity when blurRadius == 0
  const beginStopOffset = radius / (radius + blurRadius) - 0.000001;

  // Defs are used to define gradients and masks to be used after to create the keyhole effect.
  // The gradient is used to create the circle with blur effect.
  // The mask is used to create the rectangle that will cut off in the backdrop.
  const defs = (
    <defs>
      {shape === SHAPES.CIRCLE && (
        <radialGradient
          id={`radialGradient-${svgId}`}
          gradientUnits="userSpaceOnUse"
          cx={xCenter}
          cy={yCenter}
          r={radius + blurRadius}
        >
          <stop offset={beginStopOffset} stopColor="#00000000" />
          <stop offset="1" stopColor={backgroundColor} />
        </radialGradient>
      )}
      {shape === SHAPES.RECTANGLE && (
        <mask id={`mask-${svgId}`}>
          <rect width={deviceWidth} height={deviceHeight} fill="white" />
          <rect
            x={xOrigin}
            y={yOrigin}
            width={width}
            height={height}
            rx={cornerRadius}
            fill="black"
          />
        </mask>
      )}
    </defs>
  );

  // This is the rectangle that fills the entire device screen.
  // Just creates the keyhole if has targetArea
  const fillRect = (
    <rect
      width={deviceWidth}
      height={deviceHeight}
      fill={
        targetArea && shape === SHAPES.CIRCLE
          ? `url(#radialGradient-${svgId})`
          : backgroundColor
      }
      mask={
        targetArea && shape === SHAPES.RECTANGLE
          ? `url(#mask-${svgId})`
          : undefined
      }
    />
  );

  const isTooltip = isAnchored || isFloating;

  const handleCrossIconOrButton = () => {
    if (isTooltip) {
      if ((isAnchored && hasSelectors) || isFloating) {
        return (
          <TargetPositionWrapper
            top={crossHairPosition.top}
            left={crossHairPosition.left}
          >
            <TargetPositionCrossButton onClick={onTargetPlacementChange}>
              <CrossIcon />
            </TargetPositionCrossButton>
          </TargetPositionWrapper>
        );
      }

      if (isAnchored && !hasScreen && !hasSelector) {
        return (
          <SelectScreenButton
            top={deviceHeight / 2}
            left={deviceWidth / 2}
            onClick={onToggleFloatingScreen}
          >
            Select captured screen
          </SelectScreenButton>
        );
      }
    }

    return null;
  };

  return (
    <>
      <Backdrop id="backdrop">
        {defs}
        {fillRect}
      </Backdrop>
      {!isImageService && handleCrossIconOrButton()}
    </>
  );
}

BackdropKeyhole.propTypes = {
  backdrop: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  backdropKeyhole: PropTypes.shape({
    config: PropTypes.shape({
      shape: PropTypes.oneOf(Object.values(SHAPES)),
      cornerRadius: PropTypes.number,
      blurRadius: PropTypes.number,
      spreadRadius: PropTypes.number,
    }),
  }),
  targetArea: TargetAreaShape,
  deviceSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  isAnchored: PropTypes.bool,
  isFloating: PropTypes.bool,
  isImageService: PropTypes.bool,
  hasScreen: PropTypes.bool,
  hasSelector: PropTypes.bool,
  hasSelectors: PropTypes.bool,
  insets: ScreenInsetsShape,
  onTargetPlacementChange: PropTypes.func,
  onToggleFloatingScreen: PropTypes.func,
};

const mapDispatchToProps = {
  onToggleFloatingScreen: toggleFloatingScreen,
};

export default connect(null, mapDispatchToProps)(BackdropKeyhole);
