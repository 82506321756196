import { EMBED } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, EMBED_PROMOTION } from './defaults';

export default {
  id: EMBED_PROMOTION,
  name: 'Promotion',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: EMBED,
  isDefault: true,
  content: {
    id: 'dcf37cb4-809d-4afa-97e4-7c5bdc753d63',
    type: 'group',
    children: [
      {
        id: '53ec5e6b-9a7e-4879-ab59-c63c34a3ed79',
        type: 'embed',
        content: {
          id: '0d38305e-d691-45fb-9d54-f0c4126c30d0',
          items: [
            {
              distribution: 'equal',
              id: '4edc0ce2-6af8-4156-8815-6b9719ea2e80',
              items: [
                {
                  blockType: 'text',
                  content: {
                    id: '760e136e-3b0a-4941-bf75-542c93c59c78',
                    style: {
                      fontName: 'System Default Bold',
                      fontSize: 24,
                      foregroundColor: {
                        light: '#425678',
                      },
                      horizontalAlignment: 'leading',
                      marginBottom: 12,
                      marginLeading: 16,
                      marginTop: 16,
                      marginTrailing: 16,
                      textAlignment: 'leading',
                    },
                    text: 'Go further with Premium',
                    type: 'text',
                  },
                  id: '0091e636-702d-42f8-ad96-5d6bc7db40e3',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '56aa824a-b7df-4f68-8f76-883bdd23a73c',
              items: [
                {
                  blockType: 'text',
                  content: {
                    id: '20f0121e-1d70-43b4-9a16-3a8cf6e7cae6',
                    style: {
                      fontName: 'System Default Bold',
                      fontSize: 13,
                      foregroundColor: {
                        light: '#425678',
                      },
                      horizontalAlignment: 'leading',
                      marginBottom: 12,
                      marginLeading: 16,
                      marginTop: 16,
                      marginTrailing: 16,
                      textAlignment: 'leading',
                    },
                    text: 'Just $3.99/mo for your first 3 months',
                    type: 'text',
                  },
                  id: 'cc8f9465-3b01-4eb5-931c-31ef69d13f0f',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '2512967b-4320-4d13-9dac-20927bb8c195',
              items: [
                {
                  blockType: 'button',
                  content: {
                    content: {
                      blockType: 'text',
                      content: {
                        id: '7dfaba69-a505-477a-8677-fbfc523b5104',
                        style: {
                          fontSize: 18,
                          foregroundColor: {
                            light: '#FFFFFF',
                          },
                          horizontalAlignment: 'center',
                          marginBottom: 8,
                          marginLeading: 16,
                          marginTop: 8,
                          marginTrailing: 16,
                          textAlignment: 'center',
                        },
                        text: 'Learn More',
                        type: 'text',
                      },
                      id: 'a02400e9-22a3-4565-a7a0-cedd2fdd0c75',
                      style: {
                        fontSize: 17,
                        foregroundColor: {
                          light: '#FFFFFF',
                        },
                      },
                      text: 'Next',
                      type: 'block',
                    },
                    id: '1bfe380e-1b66-4843-b5b1-a78ce767f9b4',
                    style: {
                      backgroundColor: {
                        light: '#5C5CFF',
                      },
                      cornerRadius: 6,
                      marginBottom: 16,
                      marginLeading: 16,
                      marginTop: 8,
                      marginTrailing: 16,
                      paddingBottom: 12,
                      paddingLeading: 24,
                      paddingTop: 12,
                      paddingTrailing: 24,
                      width: -1,
                    },
                    type: 'button',
                  },
                  id: '946be1d5-d4a3-4237-b025-3855cd3e8ac9',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {
          '1bfe380e-1b66-4843-b5b1-a78ce767f9b4': [
            {
              config: {},
              on: 'tap',
              type: '@appcues/continue',
            },
          ],
        },
        traits: [],
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          presentationStyle: 'embed',
          style: {
            backgroundColor: {
              light: '#FFFFFF',
            },
            paddingBottom: 16,
          },
          transition: 'fade',
        },
        type: '@appcues/embedded',
      },
      {
        config: {
          buttonAppearance: 'minimal',
        },
        type: '@appcues/skippable',
      },
      {
        type: '@appcues/carousel',
      },
      {
        config: {
          style: {
            backgroundColor: {
              light: '#CCCCCC',
            },
            foregroundColor: {
              light: '#999999',
            },
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
          },
        },
        type: '@appcues/paging-dots',
      },
    ],
  },
};
