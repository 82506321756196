import styled, { css } from 'styled-components';
import {
  Button as SonarButton,
  DropdownMenu as SonarDropdownMenu,
  Input as SonarInput,
  Label as SonarLabel,
} from '@appcues/sonar';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: var(--spacing-regular);

  ${({ isGrid }) =>
    !isGrid &&
    css`
      flex: 1;
      overflow: hidden;
      padding: var(--spacing-regular) var(--spacing-regular) 0;
    `}

  > ${SonarInput} {
    ${({ isGrid }) =>
      isGrid &&
      css`
        width: 50%;
        margin-top: var(--spacing-regular);
      `}
  }
`;

export const TemplatesWrapperLabel = styled.p`
  color: var(--color-neutral-600);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  margin: var(--spacing-small) 0;

  ${({ isGrid }) =>
    isGrid &&
    `
      grid-column: 1 / -1;
      width: 100%;
      margin: 0;
    `}
`;

const gridStyles = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-regular);
  max-height: 500px;
  min-height: 235px;

  @media (max-height: 768px) {
    max-height: 380px;
  }
`;

const listStyles = css`
  flex: 1;
  margin-bottom: var(--spacing-regular);

  > * {
    margin-bottom: var(--spacing-regular);
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TemplateWrapper = styled.div`
  overflow: auto;
  padding: var(--spacing-small);
  border: 1px solid var(--border-default);
  border-radius: var(--border-radius-small);

  ${({ isGrid }) => (isGrid ? gridStyles : listStyles)}
`;

export const ActionsGroup = styled.div`
  padding: var(--spacing-regular);
  gap: var(--spacing-regular);
  box-shadow: var(--level-2);
`;

const commonButtonStyles = css`
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 12px;
`;

export const Label = styled(SonarLabel)`
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DropdownMenuTriggerButton = styled(SonarButton)`
  ${commonButtonStyles}

  border: 4px solid transparent;
`;

export const DropdownMenuContent = styled(SonarDropdownMenu.Content)`
  z-index: var(--z-index-300);
`;

export const DropdownMenu = {
  ...SonarDropdownMenu,
  Content: DropdownMenuContent,
};

const TemplateImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-100);

  > * {
    width: 100%;
    aspect-ratio: 1;

    ${({ isGrid }) => `height: ${isGrid ? '150' : '100'}px;`}
  }
`;

const TemplateCardFooter = styled.footer`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-neutral-200);
  padding: var(--spacing-small) var(--spacing-small) var(--spacing-small)
    var(--spacing-regular);
`;

const TemplateCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-x-small);
  max-width: 200px;
`;

const TemplateCardActions = styled.div`
  align-self: flex-start;
`;

const TemplateCardRoot = styled.article`
  display: flex;
  background: var(--background-level-1);
  border: 1px solid var(--border-input-default);
  border-radius: var(--border-radius-small);
  overflow: hidden;

  ${({ isGrid }) => `flex-direction: ${isGrid ? 'column' : 'row'};`}

  &:hover {
    outline: 1px solid var(--border-input-hover);
    cursor: pointer;
  }
`;

export const TemplateCard = {
  Root: TemplateCardRoot,
  ImageContainer: TemplateImageContainer,
  Info: TemplateCardInfo,
  Footer: TemplateCardFooter,
  Actions: TemplateCardActions,
};

export const ActionsWrapper = styled.div`
  display: flex;
  margin-left: var(--spacing-x-small);

  ${SonarButton} {
    ${commonButtonStyles}
  }
`;

export const TemplateNameInput = styled(SonarInput)`
  min-height: 35px;
  height: 35px;
`;
