import styled from 'styled-components';
import {
  Text as SonarText,
  TileGroup as SonarTileGroup,
  Tabs as SonarTabs,
} from '@appcues/sonar';
import { easing } from 'ext/lib/style/easing';

export const Actions = styled.section.attrs({
  role: 'toolbar',
})`
  z-index: var(--z-index-300);
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  min-height: 42px;
  padding: 6px var(--spacing-regular);
`;

export const Wrapper = styled.div`
  padding: var(--spacing-regular);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
`;

export const Text = styled(SonarText)`
  color: var(--color-neutral-600);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
`;

export const PresentationGroups = styled.div``;

export const ToggleGroupWrapper = styled.div`
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-regular);
`;

export const TileGroup = styled(SonarTileGroup)`
  && {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const PreviewPane = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: var(--bottom-bar-height);
  width: calc(100vw - var(--side-bar-width));
  background: #7a7d7f;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: none;
  transition: ${easing('opacity')};
`;

export const TabRoot = styled(SonarTabs.Root)`
  height: 100%;
`;

export const TabContent = styled(SonarTabs.Content)`
  height: 100%;
`;

export const Tabs = {
  ...SonarTabs,
  Root: TabRoot,
  Content: TabContent,
};
