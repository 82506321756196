import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { selectExperience } from 'entities/experiences';
import { selectStepChild } from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { flush, insert, patterns, prune, STEP_CHILD_CLONED } from './actions';
import sanitize from './sanitize';

function* cloneStep({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id } = payload;
    const { id: experienceId } = yield select(selectExperience);
    const { stepGroup: stepId } = yield select(selectSelected);
    const api = yield getContext('api');
    const step = yield select(selectStepChild, id);

    const response = yield call(
      api.addStep,
      experienceId,
      stepId,
      sanitize(step)
    );
    yield put(insert(response, stepId));
  } catch (error) {
    logger.error(error);
  }
}

function* updateStep({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id: stepId, delta } = payload;
    const { id } = yield select(selectExperience);
    const api = yield getContext('api');

    yield call(api.updateStep, id, stepId, delta);
    yield put(flush(stepId));
  } catch (error) {
    logger.error(error);
  }
}

function* removeStep({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id: experienceId } = yield select(selectExperience);
    const { id: stepId } = payload;
    const api = yield getContext('api');

    yield call(api.removeStep, experienceId, stepId);
    yield put(prune(stepId));
  } catch (error) {
    logger.error(error);
  }
}

export default function* stepChildrenSaga() {
  yield takeLatest(STEP_CHILD_CLONED, cloneStep);
  yield takeLatest(patterns.update, updateStep);
  yield takeLatest(patterns.remove, removeStep);
}
