import { put, takeEvery } from 'redux-saga/effects';
import { ITEM_FOCUSED } from 'ext/lib/collections';
import { TYPE as EXPERIENCE } from 'entities/experiences';
import { LOCALE, setUserPreferences } from 'lib/user-preferences';
import { updateUserPreferences, USER_PREFERENCES_UPDATED } from './actions';

function* persistUserPreferences({ payload }) {
  const { preference, value } = payload;
  yield setUserPreferences(preference, value);
}

function* updateExperience({ meta }) {
  // On change of selected experience, reset selected locale to default
  if (meta.type === EXPERIENCE) {
    yield put(updateUserPreferences('locale', LOCALE));
  }
}

export default function* saga() {
  yield takeEvery(USER_PREFERENCES_UPDATED, persistUserPreferences);
  yield takeEvery(ITEM_FOCUSED, updateExperience);
}
