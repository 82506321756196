import React, { Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon } from '@appcues/sonar';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import useToggle from 'ext/lib/hooks/use-toggle';
import { Tooltip } from 'ext/components/ui';
import DeleteModal from 'ext/components/DeleteModal';
import { selectExperience, templateApply } from 'entities/experiences';
import { isEmbedExperience, EXPERIENCE_TYPES } from 'lib/experiences';
import {
  selectFloatingScreen,
  toggleFloatingScreen,
} from 'entities/user-interface';
import {
  selectStepGroup,
  clone as cloneStepGroup,
  remove as removeStepGroup,
} from 'entities/step-groups';
import {
  clone as cloneStepChild,
  remove as removeStepChild,
} from 'entities/step-children';
import {
  create as createTemplate,
  update as updateTemplate,
  remove as removeTemplate,
  selectTemplates,
  Shape as TemplateShape,
} from 'entities/templates';
import { selectSelected } from 'entities/selected';
import { LAYOUT_TRAITS, TRAIT_LABELS } from 'lib/trait';
import { THEMES } from 'lib/user-preferences';
import { DEFAULT_LOCALE } from 'lib/localization';
import { MODES } from 'lib/templates';
import { selectUserPreferences } from 'entities/user-preferences';
import { capitalize } from 'utils/strings-handler';
import FloatingScreenCapture from 'components/FloatingScreenCapture';
import Divider from 'components/Divider';
import { HeaderCloneMenu } from './HeaderCloneMenu';
import { HeaderTemplateMenu } from './HeaderTemplateMenu';
import { Actions, Label, ScreenCaptureIcon } from './styled';

const [LIGHT] = THEMES;

export function HeaderActions({
  trait,
  experienceType,
  selectedExperience,
  selectedStepGroup,
  selectedStepChild,
  floatingScreenOpen,
  children,
  templates,
  canApplyTemplate,
  onGroupRemove,
  onStepClone,
  onStepGroupClone,
  onStepRemove,
  onTemplateSave,
  onTemplateUpdate,
  onTemplateRemove,
  onTemplateApply,
  onToggleFloatingScreen,
}) {
  const $actionsToolbar = useRef();
  const { track } = useAnalytics();
  const [deleteStep, toggleDeleteStep] = useToggle();

  const isSingleStep = children.length === 1;

  const handleClone = () => {
    track('Mobile Builder interaction', {
      name: `Cloned ${TRAIT_LABELS[trait]}`,
      component: 'HeaderActions',
    });
    onStepClone(selectedStepChild);
  };

  const handleGroupClone = () => {
    track('Mobile Builder interaction', {
      name: `Cloned ${TRAIT_LABELS[trait]} to new group`,
      component: 'HeaderActions',
    });
    onStepGroupClone(selectedStepGroup, selectedStepChild);
  };

  const handleDelete = () => {
    track('Mobile Builder interaction', {
      name: `Deleted ${TRAIT_LABELS[trait]}`,
      component: 'HeaderActions',
    });

    // If there's only one step in the group, we need to remove the group
    if (isSingleStep) {
      onGroupRemove(selectedStepGroup, selectedExperience);
    } else {
      // We remove the selected step child from the step group
      onStepRemove(selectedStepChild, selectedStepGroup);
    }
  };

  const handleDeleteToggle = () => {
    track('Mobile Builder view', {
      name: `Opened Delete ${TRAIT_LABELS[trait]}`,
      component: 'HeaderActions',
    });
    toggleDeleteStep();
  };

  const handleScreenCapture = () => {
    track('Mobile Builder view', {
      name: `Opened Screen Capture ${TRAIT_LABELS[trait]}`,
      component: 'HeaderActions',
    });
    onToggleFloatingScreen();
  };

  const handleApplyTemplate = template => {
    onTemplateApply({
      mode: MODES.REPLACE_CHILD,
      template,
    });
  };

  const handleTemplateCreate = templateName => {
    onTemplateSave({
      name: templateName,
      parentId: selectedStepGroup,
      childId: selectedStepChild,
    });
  };

  // Content can be updated from Save modal, name can be updated from renaming in the Add modal
  const handleTemplateUpdate = ({
    id: templateId,
    updateContent = false,
    name,
  }) => {
    onTemplateUpdate({
      id: templateId,
      ...(name && { name }),
      ...(updateContent && {
        parentId: selectedStepGroup,
        childId: selectedStepChild,
      }),
    });
  };

  const screenCaptureLabel = (
    <Label>
      Set background screen for <br />
      preview and/or tooltip targeting
    </Label>
  );

  const actionButtons = [
    {
      id: 'screen-capture',
      icon: ScreenCaptureIcon,
      onClick: handleScreenCapture,
      label: screenCaptureLabel,
      active: floatingScreenOpen,
      hasDivider: true,
      hidden: isEmbedExperience(experienceType),
    },
    {
      id: 'clone',
      icon: faClone,
      onClick: handleClone,
      hidden: !isEmbedExperience(experienceType),
    },
    {
      id: 'clone',
      customContent: (
        <HeaderCloneMenu
          onStepClone={handleClone}
          onStepGroupClone={handleGroupClone}
        />
      ),
      hidden: isEmbedExperience(experienceType),
    },
    {
      id: 'delete',
      icon: faTrashAlt,
      onClick: handleDeleteToggle,
    },
    {
      id: 'templates',
      customContent: (
        <HeaderTemplateMenu
          templates={templates.filter(t => t.contentType === trait)}
          handleApplyTemplate={
            canApplyTemplate ? handleApplyTemplate : undefined
          }
          handleTemplateSave={handleTemplateCreate}
          handleTemplateUpdate={handleTemplateUpdate}
          handleTemplateRemove={onTemplateRemove}
        />
      ),
    },
  ];

  return (
    <>
      <Actions ref={$actionsToolbar}>
        {actionButtons.map(
          ({
            id,
            icon,
            label,
            customContent,
            active,
            hasDivider,
            onClick,
            hidden,
          }) =>
            !hidden && (
              <Fragment key={id}>
                {customContent || (
                  <Tooltip
                    label={label || capitalize(id)}
                    // eslint-disable-next-line @appcues/jsx-props-no-spreading
                    {...(active && { visible: false })}
                  >
                    <Button
                      aria-label={id}
                      variant="tertiary"
                      aria-pressed={active}
                      onClick={onClick}
                    >
                      {typeof icon === 'object' ? <Icon icon={icon} /> : icon()}
                    </Button>
                  </Tooltip>
                )}
                {hasDivider && <Divider size="x-small" />}
              </Fragment>
            )
        )}
      </Actions>

      <DeleteModal
        theme={LIGHT}
        onDelete={handleDelete}
        onClose={toggleDeleteStep}
        visible={deleteStep}
      />

      {floatingScreenOpen && (
        <FloatingScreenCapture
          refToAttach={$actionsToolbar}
          onDismiss={onToggleFloatingScreen}
        />
      )}
    </>
  );
}

HeaderActions.propTypes = {
  trait: PropTypes.oneOf(LAYOUT_TRAITS),
  experienceType: PropTypes.oneOf(EXPERIENCE_TYPES),
  selectedExperience: PropTypes.string,
  selectedStepGroup: PropTypes.string,
  selectedStepChild: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.string),
  templates: PropTypes.arrayOf(TemplateShape),
  canApplyTemplate: PropTypes.bool,
  floatingScreenOpen: PropTypes.bool,
  onGroupRemove: PropTypes.func,
  onStepClone: PropTypes.func,
  onStepGroupClone: PropTypes.func,
  onStepRemove: PropTypes.func,
  onTemplateSave: PropTypes.func,
  onTemplateUpdate: PropTypes.func,
  onTemplateRemove: PropTypes.func,
  onTemplateApply: PropTypes.func,
  onToggleFloatingScreen: PropTypes.func,
};

const mapStateToProps = state => {
  const { id: selectedExperience, type: experienceType } =
    selectExperience(state);
  const { stepGroup: selectedStepGroup, stepChild: selectedStepChild } =
    selectSelected(state) ?? {};
  const stepGroup = selectStepGroup(state, selectedStepGroup) ?? {};
  const floatingScreenOpen = selectFloatingScreen(state);
  const { children = [] } = stepGroup;
  const templates = Object.values(selectTemplates(state));
  const canApplyTemplate =
    selectUserPreferences(state).locale.id === DEFAULT_LOCALE.id;

  return {
    experienceType,
    selectedExperience,
    selectedStepGroup,
    selectedStepChild,
    floatingScreenOpen,
    children,
    templates,
    canApplyTemplate,
  };
};

const mapDispatchToProps = {
  onGroupRemove: removeStepGroup,
  onStepClone: cloneStepChild,
  onStepGroupClone: cloneStepGroup,
  onStepRemove: removeStepChild,
  onTemplateSave: createTemplate,
  onTemplateUpdate: updateTemplate,
  onTemplateRemove: removeTemplate,
  onTemplateApply: templateApply,
  onToggleFloatingScreen: toggleFloatingScreen,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderActions);
