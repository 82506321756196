import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const Image = styled.img`
  max-height: 90%;
  max-width: 90%;
`;

const movingGradientAnimation = keyframes`
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
`;

export const LoadingWrapper = styled(Wrapper)`
  background: linear-gradient(to right, #f4f7fa 20%, #e7ecf3 50%, #f4f7fa 80%);
  background-size: 500px 100px;
  animation: ${movingGradientAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;
