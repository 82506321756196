import PropTypes from 'prop-types';
import { ContentShape } from 'entities/step-children';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  contentType: PropTypes.string,
  content: ContentShape,
  createdAt: PropTypes.number,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
});
