import { createLifecycleFor } from 'ext/lib/collections';

export const TYPE = 'templates';

export const {
  patterns,
  remove,
  prune,
  create,
  insert,
  update,
  flush,
  send,
  resolve,
  reject,
} = createLifecycleFor(TYPE);
