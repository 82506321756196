import { MODAL } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, MODAL_SURVEY } from './defaults';

export default {
  id: MODAL_SURVEY,
  name: 'Survey',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: MODAL,
  isDefault: true,
  content: {
    id: '1a17665a-9898-44ba-b1c9-707eebe25d3d',
    type: 'group',
    children: [
      {
        id: '2d6bde97-597e-44a1-bac6-61597576a174',
        type: 'modal',
        content: {
          id: 'ce374f2b-d30b-48a0-a55c-8f96f0aba212',
          items: [
            {
              distribution: 'equal',
              id: '056e85f5-18ee-46d0-8f26-cb6f2463ddfe',
              items: [
                {
                  blockType: 'image',
                  content: {
                    accessibilityLabel: '',
                    blurHash: '',
                    contentMode: 'fill',
                    id: 'f27cca2b-0063-4379-90eb-3a0e660cf79e',
                    imageUrl:
                      'https://res.cloudinary.com/dnjrorsut/image/upload/v1674156350/106160/nfmcrbdqbi7osy2b6oje.png',
                    intrinsicSize: {
                      height: 442,
                      width: 794,
                    },
                    style: {
                      horizontalAlignment: 'center',
                      verticalAlignment: 'center',
                      width: 120,
                    },
                    type: 'image',
                  },
                  id: 'b98b6f10-272b-40e0-a564-081eb8e5e179',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '8562cada-6a8c-4983-ba9c-7447b086353e',
              items: [
                {
                  blockType: 'singleSelect',
                  content: {
                    accentColor: {
                      light: '#FFFFFF',
                    },
                    controlPosition: 'leading',
                    displayFormat: 'verticalList',
                    errorLabel: {
                      id: 'a2478229-d55d-4d81-84b9-1bf05fdb2789',
                      style: {
                        fontSize: 14,
                        foregroundColor: {
                          light: '#D6216B',
                        },
                        horizontalAlignment: 'leading',
                        marginTop: 10,
                        textAlignment: 'leading',
                      },
                      text: 'Required field',
                      type: 'text',
                    },
                    id: '854c2569-3c18-4d68-9b4b-a4e77d1e12db',
                    label: {
                      id: '8a7f9e54-6b42-4377-8b39-79b93ab7ec1c',
                      style: {
                        fontName: 'Mulish-Light',
                        fontSize: 22,
                        foregroundColor: {
                          light: '#ffffff',
                        },
                        horizontalAlignment: 'leading',
                        marginBottom: 12,
                        textAlignment: 'leading',
                      },
                      text: 'What best describes your role?',
                      type: 'text',
                    },
                    options: [
                      {
                        content: {
                          id: '8d5f26b6-201c-4368-953b-14e2a6979865',
                          style: {
                            fontName: 'Mulish',
                            fontSize: 16,
                            foregroundColor: {
                              light: '#ffffff',
                            },
                            textAlignment: 'leading',
                          },
                          text: 'Product management',
                          type: 'text',
                        },
                        value: 'Product management',
                      },
                      {
                        content: {
                          id: '6a890aea-57ff-4a5a-8392-3e5113f483ba',
                          style: {
                            fontName: 'Mulish',
                            fontSize: 16,
                            foregroundColor: {
                              light: '#ffffff',
                            },
                            textAlignment: 'leading',
                          },
                          text: 'Design',
                          type: 'text',
                        },
                        value: 'Design',
                      },
                      {
                        content: {
                          id: 'e9a39029-e86e-4ab3-aac9-3b79a181087b',
                          style: {
                            fontName: 'Mulish',
                            fontSize: 16,
                            foregroundColor: {
                              light: '#ffffff',
                            },
                            textAlignment: 'leading',
                          },
                          text: 'Engineering',
                          type: 'text',
                        },
                        value: 'Engineering',
                      },
                      {
                        content: {
                          id: '88f6175d-ff3e-41e3-838c-03f7bc441752',
                          style: {
                            fontName: 'Mulish',
                            fontSize: 16,
                            foregroundColor: {
                              light: '#ffffff',
                            },
                            textAlignment: 'leading',
                          },
                          text: 'Marketing',
                          type: 'text',
                        },
                        value: 'Marketing',
                      },
                      {
                        content: {
                          id: '64e2dd47-aafd-4a58-bc8c-e47d55fd37a3',
                          style: {
                            fontName: 'Mulish',
                            fontSize: 16,
                            foregroundColor: {
                              light: '#ffffff',
                            },
                            textAlignment: 'leading',
                          },
                          text: 'Other',
                          type: 'text',
                        },
                        value: 'Other',
                      },
                    ],
                    selectMode: 'single',
                    selectedColor: {
                      light: '#bfe0ff',
                    },
                    style: {
                      horizontalAlignment: 'leading',
                      marginBottom: 0,
                      marginLeading: 32,
                      marginTop: 16,
                      marginTrailing: 36,
                    },
                    type: 'optionSelect',
                    unselectedColor: {
                      light: '#fefeff',
                    },
                  },
                  id: 'a3bb407b-1f8d-443e-b7f7-c8fd902e5a20',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '2cd02c27-ae3d-417a-8a0a-2ee936610546',
              items: [
                {
                  blockType: 'button',
                  content: {
                    content: {
                      id: 'af9343ec-0e0e-43db-bffe-9dcd66c7f764',
                      style: {
                        fontName: 'Mulish-Bold',
                        fontSize: 18,
                        foregroundColor: {
                          light: '#FFFFFF',
                        },
                      },
                      text: 'Submit',
                      type: 'text',
                    },
                    id: '507afbc1-c4ba-4e0b-a643-eca8ea76a01e',
                    style: {
                      backgroundColor: {
                        light: '#11142e00',
                      },
                      borderColor: {
                        light: '#ffffff',
                      },
                      borderWidth: 3,
                      cornerRadius: 6,
                      marginBottom: 16,
                      marginLeading: 32,
                      marginTop: 8,
                      marginTrailing: 32,
                      paddingBottom: 12,
                      paddingLeading: 32,
                      paddingTop: 12,
                      paddingTrailing: 32,
                      width: -1,
                    },
                    type: 'button',
                  },
                  id: '2546a83b-682c-483b-ab1e-78470b959b07',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {
          '507afbc1-c4ba-4e0b-a643-eca8ea76a01e': [
            {
              on: 'tap',
              type: '@appcues/submit-form',
            },
            {
              on: 'tap',
              type: '@appcues/continue',
            },
          ],
        },
        createdAt: 1674233061951,
        createdBy: '0e88e870-2fa9-4d45-b60d-d1809f0c5c68',
        traits: [],
        updatedAt: 1674233061951,
        updatedBy: '0e88e870-2fa9-4d45-b60d-d1809f0c5c68',
        contentType: 'application/json',
        parentId: '2c0cb570-df25-48fd-a6a1-93984cb15a56',
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          presentationStyle: 'dialog',
          style: {
            backgroundColor: {
              dark: '#FFFFFF00',
              light: '#FFFFFF00',
            },
            cornerRadius: 8,
            paddingBottom: 16,
            paddingTop: 32,
            shadow: {
              color: {
                light: '#777777EE',
              },
              radius: 14,
              x: 0,
              y: 3,
            },
          },
        },
        type: '@appcues/modal',
      },
      {
        config: {
          style: {
            backgroundColor: {
              light: '#AEAAFF',
            },
            foregroundColor: {
              light: '#5C5CFF',
            },
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
          },
        },
        type: '@appcues/paging-dots',
      },
      {
        config: {
          content: {
            id: '2b653174-823b-4409-8e91-583afe5a937b',
            imageUrl:
              'https://res.cloudinary.com/dnjrorsut/image/upload/v1662654207/mobile-builder/spacer.gif',
            style: {
              backgroundImage: {
                contentMode: 'fill',
                horizontalAlignment: 'center',
                imageUrl:
                  'https://res.cloudinary.com/dnjrorsut/image/upload/v1674156194/106160/jdab6q6bxmhkus5dxcf9.png',
                intrinsicSize: {
                  height: 570,
                  width: 360,
                },
                verticalAlignment: 'center',
              },
            },
            type: 'image',
          },
        },
        type: '@appcues/background-content',
      },
      {
        config: {
          backgroundColor: {
            light: '#0000004D',
          },
        },
        type: '@appcues/backdrop',
      },
    ],
  },
};
