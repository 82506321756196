/**
 *  Templates modes
 *
 *  Used to define the mode of template operation.
 *
 *  REPLACE_CHILD: When the template is loaded to replace the current step child.
 *  ADD_CHILD: When the template selected is added to a existing group.
 *  ADD_GROUP: When the template selected creates a new step group.
 *
 * @constant
 */
export const MODES = {
  REPLACE_CHILD: 'replace-child',
  ADD_CHILD: 'add-child',
  ADD_GROUP: 'add-group',
};

/**
 *  Templates list contexts
 *
 *  Used to define which context the template list will be displayed.
 *
 *  LIST_FIRST_STEP: When the list is displayed in the sidebar to create the first step.
 *  LIST_NEW_STEP: When the list is displayed in the sidebar to create a new step.
 *  GRID_LOAD_MODAL: When the list is displayed as grid in the load template modal.
 *
 * @constant
 */
export const LIST_FIRST_STEP = 'list-first-step';
export const LIST_NEW_STEP = 'list-new-step';
export const GRID_LOAD_MODAL = 'grid-load-modal';

export const TEMPLATE_CONTEXTS = [
  LIST_FIRST_STEP,
  LIST_NEW_STEP,
  GRID_LOAD_MODAL,
];
