import { createLifecycleFor, createMoveActionsFor } from 'ext/lib/collections';

export const TYPE = 'stepGroups';

export const {
  create,
  insert,
  update,
  flush,
  reject,
  remove,
  prune,
  focus,
  patterns,
} = createLifecycleFor(TYPE);

export const STEP_GROUP_CLONED = 'STEP_GROUP_CLONED';
export const clone = (parentId, childId) => ({
  type: STEP_GROUP_CLONED,
  payload: { parentId, childId },
});

export const { move: moveStepChild, patterns: movePatterns } =
  createMoveActionsFor(TYPE);
