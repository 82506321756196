import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyleShape } from 'entities/step-children';
import { SkippableShape } from 'entities/step-groups';
import { EMBED, IMAGE_SERVICE_SELECTOR, PREVIEW_EMBED_ID } from 'lib/trait';
import { PRESENTATIONS } from 'lib/presentation';
import { THEMES } from 'lib/user-preferences';
import { transformStyles } from 'components/Editor/Primitives';
import CloseX from './CloseX';
import PagingDots from './PagingDots';

const EmbedContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  // This allows fixed elements to be relative to the embed and not the viewport
  transform: translate(0);
`;

const PreviewEmbed = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-width: 0px;
  width: 100%;
  z-index: 0;

  ${({ customStyle }) => customStyle}
`;

const Embed = ({
  id,
  theme,
  skippable,
  pagingDots,
  backgroundContent,
  style,
  children,
  handleClick,
}) => {
  let styles = { ...style };

  if (backgroundContent) {
    const {
      type,
      style: backgroundColor,
      ...backgroundImage
    } = backgroundContent;

    styles = {
      ...styles,
      backgroundImage,
      ...backgroundColor,
    };
  }

  return (
    <EmbedContainer id={IMAGE_SERVICE_SELECTOR}>
      <PreviewEmbed
        id={id}
        customStyle={transformStyles(styles, theme)}
        onClick={handleClick}
      >
        <CloseX skippable={skippable} theme={theme} isEmbed />
        {children}
        <PagingDots style={pagingDots?.style} theme={theme} isEmbed />
      </PreviewEmbed>
    </EmbedContainer>
  );
};

Embed.propTypes = {
  id: PropTypes.oneOf([PREVIEW_EMBED_ID]),
  presentation: PropTypes.oneOf(Object.keys(PRESENTATIONS[EMBED])),
  theme: PropTypes.oneOf(THEMES),
  skippable: SkippableShape,
  pagingDots: PropTypes.shape({
    style: StyleShape,
  }),
  backgroundContent: PropTypes.shape({
    type: PropTypes.string,
    style: StyleShape,
  }),
  style: StyleShape,
  children: PropTypes.node,
  handleClick: PropTypes.func,
};

export default Embed;
