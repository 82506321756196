import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@appcues/sonar';
import { format } from 'date-fns';
import { Wrapper, Action, Date as DateSpan } from './CreatedBy.styled';

export function CreatedBy({ avatarUrl, fullName, createdAt, updatedAt }) {
  const formattedDate =
    updatedAt && format(new Date(updatedAt), 'MMMM d, yyyy');

  if (!formattedDate) {
    return null;
  }

  const isCreation = updatedAt === createdAt;
  const action = `${isCreation ? 'created' : 'updated'} on`;

  return (
    <Wrapper>
      <Avatar url={avatarUrl ?? ''} fullName={fullName ?? ''} size="small" />
      <Action>
        {action} <DateSpan>{formattedDate}</DateSpan>
      </Action>
    </Wrapper>
  );
}

CreatedBy.propTypes = {
  avatarUrl: PropTypes.string,
  fullName: PropTypes.string,
  createdAt: PropTypes.number,
  updatedAt: PropTypes.number,
};

export default CreatedBy;
