import { fork } from 'redux-saga/effects';

/**
 * NOTE: these imports do NOT go through the index.js for each entity
 *
 * THIS IS INTENTIONAL. The sagas are not a public API, unlike
 * actions and selectors which ARE appropriate to import in components.
 */

/* eslint-disable no-restricted-imports */
import accountsSaga from 'ext/entities/accounts/saga';
import userPropertiesSaga from 'ext/entities/user-properties/saga';
import userPreferencesSaga from 'entities/user-preferences/saga';
import appsSaga from 'entities/apps/saga';
import authSaga from 'entities/auth/saga';
import experienceSaga from 'entities/experiences/saga';
import experiencesSummarySaga from 'entities/experiences/summary-saga';
import historyLogSaga from 'entities/history-log/saga';
import localesSaga from 'entities/locales/saga';
import locationSaga from 'lib/location/location-saga';
import screenSaga from 'entities/screens/saga';
import screensSummarySaga from 'entities/screens/summary-saga';
import screenshotsSaga from 'entities/screenshots/saga';
import sdkToken from 'entities/screens/sdk-token-saga';
import stepGroupsSaga from 'entities/step-groups/saga';
import stepChildrenSaga from 'entities/step-children/saga';
import tagsSaga from 'entities/tags/saga';
import templatesSaga from 'entities/templates/saga';
import userSaga from 'entities/user/saga';
/* eslint-enable no-restricted-imports */

export default function* root() {
  yield fork(accountsSaga);
  yield fork(appsSaga);
  yield fork(authSaga);
  yield fork(experienceSaga);
  yield fork(experiencesSummarySaga);
  yield fork(historyLogSaga);
  yield fork(localesSaga);
  yield fork(locationSaga);
  yield fork(screenSaga);
  yield fork(screensSummarySaga);
  yield fork(screenshotsSaga);
  yield fork(stepGroupsSaga);
  yield fork(stepChildrenSaga);
  yield fork(tagsSaga);
  yield fork(templatesSaga);
  yield fork(sdkToken);
  yield fork(userSaga);
  yield fork(userPreferencesSaga);
  yield fork(userPropertiesSaga);
}
