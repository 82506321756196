import { EMBED } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, EMBED_SURVEY } from './defaults';

export default {
  id: EMBED_SURVEY,
  name: 'Survey',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: EMBED,
  isDefault: true,
  content: {
    id: '4527e60d-737e-49ed-82d8-78a30dc3093d',
    type: 'group',
    children: [
      {
        id: '3c2c1fa8-672d-4dad-9077-8e286b1ce1f1',
        type: 'embed',
        content: {
          id: '49b70940-9b1a-433f-8953-42d702b38441',
          items: [
            {
              distribution: 'equal',
              id: '3722bc22-4231-428f-88a1-7ef3f99f5359',
              items: [
                {
                  blockType: 'rating',
                  content: {
                    controlPosition: 'hidden',
                    displayFormat: 'horizontalList',
                    errorLabel: {
                      id: '2d2ee453-8dfa-4a6d-95be-ab6932a02184',
                      style: {
                        fontSize: 14,
                        foregroundColor: {
                          light: '#D6216B',
                        },
                        horizontalAlignment: 'leading',
                        marginTop: 10,
                        textAlignment: 'leading',
                      },
                      text: 'Required field',
                      type: 'text',
                    },
                    id: '647e8e19-74ea-4a30-bda3-62f1b2da7e92',
                    label: {
                      id: '71284792-931f-43d1-b53e-0b1dfa6190d8',
                      style: {
                        fontName: 'System Default Bold',
                        fontSize: 17,
                        foregroundColor: {
                          light: '#425678',
                        },
                        horizontalAlignment: 'leading',
                        marginBottom: 12,
                        textAlignment: 'leading',
                      },
                      text: 'How satisfied are you with our app?',
                      type: 'text',
                    },
                    options: [
                      {
                        content: {
                          id: 'bd822b58-adb5-48db-80a4-d1b177c892ba',
                          style: {
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '😦',
                          type: 'text',
                        },
                        id: '59216a09-58d1-4a4a-a136-9ba613c18c54',
                        selectedContent: {
                          id: 'f909dcf7-183f-4a9e-8fde-0fe6360f0548',
                          style: {
                            backgroundColor: {
                              light: '#000000',
                            },
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '😦',
                          type: 'text',
                        },
                        value: '😦',
                      },
                      {
                        content: {
                          id: '9e6f2825-f0fa-485a-baee-3fc09f328d80',
                          style: {
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '🙁',
                          type: 'text',
                        },
                        id: '59216a09-58d1-4a4a-a136-9ba613c18c54',
                        selectedContent: {
                          id: '9f620f15-0e6b-461b-9cb4-83fdd211dd9b',
                          style: {
                            backgroundColor: {
                              light: '#000000',
                            },
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '🙁',
                          type: 'text',
                        },
                        value: '🙁',
                      },
                      {
                        content: {
                          id: '666aea4a-882b-41da-a4ca-c4423907f570',
                          style: {
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '😐',
                          type: 'text',
                        },
                        id: '59216a09-58d1-4a4a-a136-9ba613c18c54',
                        selectedContent: {
                          id: '9fcc4b8c-6100-497e-b4ed-855b02f6bc70',
                          style: {
                            backgroundColor: {
                              light: '#000000',
                            },
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '😐',
                          type: 'text',
                        },
                        value: '😐',
                      },
                      {
                        content: {
                          id: '2629ce3b-8536-43d4-9652-a489e7d810b9',
                          style: {
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '🙂',
                          type: 'text',
                        },
                        id: '59216a09-58d1-4a4a-a136-9ba613c18c54',
                        selectedContent: {
                          id: '927a038b-f265-48ed-b4d8-339ee66817c5',
                          style: {
                            backgroundColor: {
                              light: '#000000',
                            },
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '🙂',
                          type: 'text',
                        },
                        value: '🙂',
                      },
                      {
                        content: {
                          id: '05eb47da-90fe-48cc-92e4-4dc71193bbf0',
                          style: {
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '😀',
                          type: 'text',
                        },
                        id: '59216a09-58d1-4a4a-a136-9ba613c18c54',
                        selectedContent: {
                          id: '86d15828-df06-4671-a4cf-444959bb7437',
                          style: {
                            backgroundColor: {
                              light: '#000000',
                            },
                            cornerRadius: 8,
                            fontName: 'System Default Regular',
                            fontSize: 30,
                            foregroundColor: {
                              light: '#627293',
                            },
                            height: 48,
                            marginLeading: 4,
                            marginTrailing: 4,
                            width: 48,
                          },
                          text: '😀',
                          type: 'text',
                        },
                        value: '😀',
                      },
                    ],
                    ratingType: 'ratingEmoji',
                    selectMode: 'single',
                    style: {
                      horizontalAlignment: 'center',
                      marginBottom: 12,
                      marginLeading: 24,
                      marginTop: 12,
                      marginTrailing: 24,
                      width: -1,
                    },
                    type: 'optionSelect',
                  },
                  id: '3934139d-bfa8-4ee9-ae6e-e6695e072601',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: 'd49cfd8d-2c98-41fe-b747-bd6cb0896747',
              items: [
                {
                  blockType: 'button',
                  content: {
                    content: {
                      blockType: 'text',
                      content: {
                        id: 'c565d527-b0a0-4257-9797-27a289601d76',
                        style: {
                          fontSize: 17,
                          foregroundColor: {
                            light: '#5C5CFF',
                          },
                          horizontalAlignment: 'center',
                          marginBottom: 0,
                          marginLeading: 0,
                          marginTop: 0,
                          marginTrailing: 0,
                          textAlignment: 'center',
                        },
                        text: 'Submit',
                        type: 'text',
                      },
                      id: '6180b35a-8fc9-489d-8f16-dd83ac651fb8',
                      style: {
                        fontSize: 17,
                        foregroundColor: {
                          light: '#FFFFFF',
                        },
                      },
                      text: 'Next',
                      type: 'block',
                    },
                    id: '39d28570-7b6f-48b8-a706-21c3ede41e64',
                    style: {
                      backgroundColor: {
                        light: '#FFFFFF',
                      },
                      borderColor: {
                        light: '#5c5cff',
                      },
                      borderWidth: 2,
                      cornerRadius: 6,
                      marginBottom: 16,
                      marginLeading: 24,
                      marginTop: 8,
                      marginTrailing: 24,
                      paddingBottom: 16,
                      paddingLeading: 24,
                      paddingTop: 16,
                      paddingTrailing: 24,
                      width: -1,
                    },
                    type: 'button',
                  },
                  id: '26217669-42c5-4591-8229-b37e50c2b4b8',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {
          '39d28570-7b6f-48b8-a706-21c3ede41e64': [
            {
              on: 'tap',
              type: '@appcues/submit-form',
            },
            {
              config: {},
              on: 'tap',
              type: '@appcues/continue',
            },
          ],
        },
        traits: [],
        createdAt: 1725391384956,
        createdBy: 'jv7nhTbocqN5btJzQ1w7LqFsPLY2',
        updatedAt: 1725391384956,
        updatedBy: 'jv7nhTbocqN5btJzQ1w7LqFsPLY2',
        contentType: null,
        parentId: '66a059bd-ad82-4918-8d92-6207330168e5',
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          presentationStyle: 'embed',
          style: {
            backgroundColor: {
              light: '#FFFFFF',
            },
            paddingBottom: 16,
          },
          transition: 'fade',
        },
        type: '@appcues/embedded',
      },
      {
        config: {
          buttonAppearance: 'minimal',
        },
        type: '@appcues/skippable',
      },
    ],
  },
};
