import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectScreenshot,
  loadTemplateScreenshot,
  getImageHashKeys,
} from 'entities/screenshots';
import PlaceholderIcon from './PlaceholderIcon';
import { Wrapper, Image, LoadingWrapper } from './styled';

const Screenshot = ({ url, id, version, onLoad }) => {
  useEffect(() => {
    if (!url) {
      onLoad({ id, version });
    }
  }, [url, id, version, onLoad]);

  return url ? (
    <Wrapper>
      <Image src={url} />
    </Wrapper>
  ) : (
    <LoadingWrapper>
      <PlaceholderIcon />
    </LoadingWrapper>
  );
};

Screenshot.propTypes = {
  id: PropTypes.string,
  version: PropTypes.number,
  url: PropTypes.string,
  onLoad: PropTypes.func,
};

const mapStateToProps = (state, { id, version }) => {
  const hash = getImageHashKeys({ id, version });
  const { url } = selectScreenshot(state, { hash }) ?? {};
  return { url };
};

const mapDispatchToProps = {
  onLoad: loadTemplateScreenshot,
};

export default connect(mapStateToProps, mapDispatchToProps)(Screenshot);
