import {
  STANDARD,
  HALF_SCREEN,
  FULL_SCREEN,
  COVER_SHEET,
  SLIDEOUT,
  ANCHORED,
  FLOATING,
  EMBED_PRESENTATION,
} from 'lib/presentation';
import { updateToLocalized } from 'lib/localization';
import { generateModalStep } from './generate-modal-step';
import { generateSlideoutStep } from './generate-slideout-step';
import { generateTooltipStep } from './generate-tooltip-step';
import { generateEmbedEmptyStep } from './generate-embed-empty-step';

export const generateStep = ({
  presentation,
  locales,
  singleStep,
  previewStepId,
}) => {
  const stepGenerators = {
    [STANDARD]: generateModalStep,
    [HALF_SCREEN]: generateModalStep,
    [FULL_SCREEN]: generateModalStep,
    [COVER_SHEET]: generateModalStep,
    [SLIDEOUT]: generateSlideoutStep,
    [FLOATING]: generateTooltipStep,
    [ANCHORED]: generateTooltipStep,
    [EMBED_PRESENTATION]: generateEmbedEmptyStep,
  };

  let step =
    stepGenerators[presentation]?.({
      presentation,
      locales,
      singleStep,
      previewStepId,
    }) ?? {};

  if (locales?.length > 0) {
    const localizedContent = updateToLocalized(step.content, locales);
    step = {
      ...step,
      content: localizedContent,
    };
  }

  return step;
};
