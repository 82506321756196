import { createCollectionReducer } from 'ext/lib/collections';
import { DEFAULT_TEMPLATE_IMAGES } from 'lib/templates';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectScreenshots = state => state[TYPE];
export const selectScreenshot = (state, { hash }) => {
  // Default templates have no version in hash, so hash === id
  return DEFAULT_TEMPLATE_IMAGES[hash]
    ? {
        id: hash,
        url: DEFAULT_TEMPLATE_IMAGES[hash],
      }
    : selectScreenshots(state)?.[hash];
};
