import { call, getContext, put, select, takeEvery } from 'redux-saga/effects';
import { INITIALIZE } from 'ext/root/root-actions';
import keyBy from 'lodash.keyby';
import { selectExperience } from 'entities/experiences';
import { selectStepGroup, revert } from 'entities/step-groups';
import { selectStepChild } from 'entities/step-children';
import { delocalize } from 'lib/localization';
import { CONTENT_TEMPLATE_TYPE } from 'lib/templates';
import { patterns, flush, insert, prune, reject, resolve } from './actions';

function* fetchTemplates() {
  const logger = yield getContext('logger');
  try {
    const api = yield getContext('api');
    const response = yield call(api.getTemplates);

    yield put(resolve(keyBy(response, 'id')));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

function* createTemplate({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { name, parentId, childId } = payload;
    const { appId, id: experienceId } = yield select(selectExperience);
    const stepParent = yield select(selectStepGroup, parentId);

    // Revert UI editor config to canonical entity values
    const updatedStepGroup = yield call(revert, stepParent);
    const stepChild = yield select(selectStepChild, childId);
    const templateBody = {
      name,
      type: CONTENT_TEMPLATE_TYPE,
      contentType: stepParent.editor.type,
      content: {
        ...updatedStepGroup,
        children: [delocalize(stepChild, 'all')],
      },
      sourceAppId: appId,
      sourceExperienceId: experienceId,
    };
    const api = yield getContext('api');
    const response = yield call(api.createTemplate, templateBody);
    yield put(insert(response));
  } catch (error) {
    logger.error(error);
  }
}

function* updateTemplate({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id, name, parentId, childId } = payload;
    let templateBody = {
      ...(name && { name }),
    };
    if (parentId && childId) {
      const { appId, id: experienceId } = yield select(selectExperience);
      const stepParent = yield select(selectStepGroup, parentId);

      // Revert UI editor config to canonical entity values
      const updatedStepGroup = yield call(revert, stepParent);
      const stepChild = yield select(selectStepChild, childId);
      templateBody = {
        ...templateBody,
        contentType: stepParent.editor.type,
        content: {
          ...updatedStepGroup,
          children: [delocalize(stepChild, 'all')],
        },
        sourceAppId: appId,
        sourceExperienceId: experienceId,
      };
    }

    const api = yield getContext('api');
    const response = yield call(api.updateTemplate, id, templateBody);
    yield put(flush(id, response));
  } catch (error) {
    logger.error(error);
  }
}

export function* removeTemplate({ payload }) {
  const { id: templateId } = payload;
  const logger = yield getContext('logger');
  try {
    const api = yield getContext('api');

    yield call(api.removeTemplate, templateId);
    yield put(prune(templateId));
  } catch (error) {
    logger.error(error);
  }
}

export default function* saga() {
  yield takeEvery(INITIALIZE, fetchTemplates);
  yield takeEvery(patterns.create, createTemplate);
  yield takeEvery(patterns.update, updateTemplate);
  yield takeEvery(patterns.remove, removeTemplate);
}
