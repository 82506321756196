import { MODAL } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, MODAL_CHANGELOG } from './defaults';

export default {
  id: MODAL_CHANGELOG,
  name: 'Changelog',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: MODAL,
  isDefault: true,
  content: {
    id: '784924a6-8c26-47aa-9a88-8370fc479eee',
    type: 'group',
    children: [
      {
        id: 'eb60189a-d0dc-49ba-91a2-9fea482eee62',
        type: 'modal',
        content: {
          id: 'f1b07f8d-65d0-4855-8c59-50401c749cf6',
          items: [
            {
              distribution: 'equal',
              id: '21424d1c-136d-4aca-8de0-639929d0c7de',
              items: [
                {
                  blockType: 'text',
                  content: {
                    id: '8676dca1-b506-4e36-bbb2-9c7defc1c050',
                    spans: [
                      {
                        style: {
                          fontName: 'System Default Bold',
                          fontSize: 30,
                        },
                        text: "What's New in App",
                      },
                    ],
                    style: {
                      fontName: 'System Default Bold',
                      fontSize: 30,
                      foregroundColor: {
                        light: '#000000',
                      },
                      horizontalAlignment: 'center',
                      marginBottom: 24,
                      marginLeading: 16,
                      marginTop: 24,
                      marginTrailing: 16,
                      textAlignment: 'center',
                    },
                    text: "What's New in App",
                    type: 'text',
                  },
                  id: '68c359c0-3cbf-436d-b2c2-0053b1d3bbfc',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '8cc18c12-e9d7-408f-92ec-49f3933416f5',
              items: [
                {
                  blockType: 'imageWithText',
                  content: {
                    distribution: 'center',
                    id: '25eeaa7d-51c2-448b-b95d-602f80c0ad36',
                    items: [
                      {
                        contentMode: 'fit',
                        id: 'aea05608-00b8-45b4-b12d-64b87b862627',
                        imageUrl:
                          'https://images.appcues.com/v1660836995/103523/roh2p8ba1mtwtzganmad.png',
                        intrinsicSize: {
                          height: 112,
                          width: 112,
                        },
                        style: {
                          marginTrailing: 8,
                          verticalAlignment: 'top',
                          width: 56,
                        },
                        type: 'image',
                      },
                      {
                        id: '3dacd4cd-7d6c-4d1c-ab8a-b595d24a81d6',
                        items: [
                          {
                            id: 'd01267db-2e6b-4137-9624-f7104479b56d',
                            spans: [
                              {
                                style: {
                                  fontSize: 15,
                                },
                                text: 'Save Templates',
                              },
                            ],
                            style: {
                              fontName: 'System Default Semibold',
                              fontSize: 15,
                              foregroundColor: {
                                light: '#000000',
                              },
                              horizontalAlignment: 'leading',
                              textAlignment: 'leading',
                            },
                            text: 'Save Templates',
                            type: 'text',
                          },
                          {
                            id: '1ee94d3c-1c6c-4ee1-a3bd-434e368b57ea',
                            spans: [
                              {
                                style: {
                                  fontSize: 15,
                                  foregroundColor: {
                                    light: '#8e8f8e',
                                  },
                                },
                                text: 'Common patterns available at your fingertips.',
                              },
                            ],
                            style: {
                              fontSize: 15,
                              foregroundColor: {
                                light: '#8e8f8e',
                              },
                              horizontalAlignment: 'leading',
                              textAlignment: 'leading',
                            },
                            text: 'Common patterns available at your fingertips.',
                            type: 'text',
                          },
                        ],
                        orientation: 'vertical',
                        style: {
                          horizontalAlignment: 'leading',
                        },
                        type: 'stack',
                      },
                    ],
                    orientation: 'horizontal',
                    style: {
                      horizontalAlignment: 'leading',
                      marginBottom: 8,
                      marginTop: 8,
                      paddingBottom: 4,
                      paddingLeading: 16,
                      paddingTop: 4,
                      paddingTrailing: 16,
                      verticalAlignment: 'top',
                    },
                    type: 'stack',
                  },
                  id: '601a0fef-d264-4e2f-8de0-a7cc1f43c61c',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '0df97696-979b-4425-964a-733d85cf3902',
              items: [
                {
                  blockType: 'imageWithText',
                  content: {
                    distribution: 'center',
                    id: 'b6f2d915-606b-4944-b242-31d205b726bb',
                    items: [
                      {
                        contentMode: 'fit',
                        id: 'eff2aa15-011f-479f-99f8-b2712f3621f1',
                        imageUrl:
                          'https://images.appcues.com/v1660836995/103523/roh2p8ba1mtwtzganmad.png',
                        intrinsicSize: {
                          height: 112,
                          width: 112,
                        },
                        style: {
                          marginTrailing: 8,
                          verticalAlignment: 'top',
                          width: 56,
                        },
                        type: 'image',
                      },
                      {
                        id: 'e57b64c5-d03c-4f58-aaba-c52bad1808d2',
                        items: [
                          {
                            id: 'f77fa970-8a71-4bb6-b61b-16a9d8bfc237',
                            spans: [
                              {
                                style: {
                                  fontSize: 15,
                                },
                                text: 'Apply Templates',
                              },
                            ],
                            style: {
                              fontName: 'System Default Semibold',
                              fontSize: 15,
                              foregroundColor: {
                                light: '#000000',
                              },
                              horizontalAlignment: 'leading',
                              textAlignment: 'leading',
                            },
                            text: 'Apply Templates',
                            type: 'text',
                          },
                          {
                            id: '202e41a0-6f7e-40e9-97b0-fa80e185f038',
                            spans: [
                              {
                                style: {
                                  fontSize: 15,
                                  foregroundColor: {
                                    light: '#8e8f8e',
                                  },
                                },
                                text: 'Update an existing step to a template or add a new step.',
                              },
                            ],
                            style: {
                              fontSize: 15,
                              foregroundColor: {
                                light: '#8e8f8e',
                              },
                              horizontalAlignment: 'leading',
                              textAlignment: 'leading',
                            },
                            text: 'Update an existing step to a template or add a new step.',
                            type: 'text',
                          },
                        ],
                        orientation: 'vertical',
                        style: {
                          horizontalAlignment: 'leading',
                        },
                        type: 'stack',
                      },
                    ],
                    orientation: 'horizontal',
                    style: {
                      horizontalAlignment: 'leading',
                      marginBottom: 8,
                      marginTop: 8,
                      paddingBottom: 4,
                      paddingLeading: 16,
                      paddingTop: 4,
                      paddingTrailing: 16,
                      verticalAlignment: 'top',
                    },
                    type: 'stack',
                  },
                  id: '2e9dd2cd-a988-4eeb-a2dc-c82a060bcc92',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '11bc9ac6-9bbb-4596-b854-ddcf0f99bac8',
              items: [
                {
                  blockType: 'button',
                  content: {
                    content: {
                      id: '6ddb1660-395a-4bf9-9d68-167e998c4c24',
                      spans: [
                        {
                          style: {
                            fontName: 'System Default Semibold',
                          },
                          text: 'Continue',
                        },
                      ],
                      style: {
                        fontName: 'System Default Semibold',
                        fontSize: 17,
                        foregroundColor: {
                          light: '#FFFFFF',
                        },
                      },
                      text: 'Continue',
                      type: 'text',
                    },
                    id: '9064468e-50d5-4d39-860f-5ad0e8a7f315',
                    style: {
                      backgroundColor: {
                        light: '#5C5CFF',
                      },
                      cornerRadius: 14,
                      marginBottom: 16,
                      marginLeading: 32,
                      marginTop: 8,
                      marginTrailing: 32,
                      paddingBottom: 15,
                      paddingLeading: 24,
                      paddingTop: 15,
                      paddingTrailing: 24,
                      width: -1,
                    },
                    type: 'button',
                  },
                  id: '5955d850-e4b8-4fb1-81e6-94c37fb1e2d4',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              sticky: 'bottom',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {
          '9064468e-50d5-4d39-860f-5ad0e8a7f315': [
            {
              config: {},
              on: 'tap',
              type: '@appcues/continue',
            },
          ],
        },
        traits: [],
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          presentationStyle: 'dialog',
          slideout: false,
          style: {
            backgroundColor: {
              light: '#FFFFFF',
            },
            cornerRadius: 8,
            paddingBottom: 16,
            shadow: {
              color: {
                light: '#00000029',
              },
              radius: 6,
              x: 0,
              y: 3,
            },
          },
          transition: 'fade',
        },
        type: '@appcues/modal',
      },
      {
        config: {
          buttonAppearance: 'hidden',
          ignoreBackdropTap: true,
        },
        type: '@appcues/skippable',
      },
      {
        type: '@appcues/carousel',
      },
      {
        config: {
          style: {
            backgroundColor: {
              light: '#CCCCCC',
            },
            foregroundColor: {
              light: '#999999',
            },
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
          },
        },
        type: '@appcues/paging-dots',
      },
      {
        config: {
          backgroundColor: {
            light: '#0000004D',
          },
        },
        type: '@appcues/backdrop',
      },
    ],
  },
};
