import {
  embedAnnouncementTemplate,
  embedPromotionTemplate,
  embedCtaTemplate,
  embedSurveyTemplate,
  tooltipIconicTemplate,
  tooltipCtaTemplate,
  modalChangelogTemplate,
  modalSurveyTemplate,
} from './defaults';

export const defaultTemplates = [
  embedAnnouncementTemplate,
  embedPromotionTemplate,
  embedCtaTemplate,
  embedSurveyTemplate,
  tooltipIconicTemplate,
  tooltipCtaTemplate,
  modalChangelogTemplate,
  modalSurveyTemplate,
];
