import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import {
  Button,
  Form,
  FormField,
  Icon,
  Input,
  InlineMessage,
  Label,
  Modal,
} from '@appcues/sonar';
import { Shape as TemplateShape } from 'entities/templates';

export function SaveTemplateModal({
  templates,
  isModalOpen,
  toggleModal,
  handleTemplateCreate,
  handleTemplateUpdate,
}) {
  const [templateName, setTemplateName] = useState('');
  const [error, setError] = useState(null);

  const { id: templateIdInUse } =
    templates.find(({ name }) => name === templateName) ?? {};

  const handleModalClose = () => {
    setTemplateName('');
    setError(null);
    toggleModal();
  };

  const handleSave = () => {
    if (templateIdInUse) {
      handleTemplateUpdate({ id: templateIdInUse, updateContent: true });
    } else {
      handleTemplateCreate(templateName);
    }

    handleModalClose();
  };

  const handleTemplateNameChange = event => {
    const { value } = event.target;

    if (!value) {
      setError('Template name is required');
      return;
    }

    setError(null);
    setTemplateName(value);
  };

  return (
    <Modal.Root open={isModalOpen} onOpenChange={handleModalClose} size="small">
      <Modal.Title>Save as template</Modal.Title>
      <Modal.Close>
        <Icon size="large" icon={faXmark} />
      </Modal.Close>
      <Form aria-label="save template form" role="form">
        <FormField compact>
          <Label htmlFor="template-name">Template</Label>
          <Input
            id="template-name"
            aria-label="template name"
            placeholder="Enter the template name"
            onChange={handleTemplateNameChange}
          />
          {(error || templateIdInUse) && (
            <InlineMessage variant="error">
              {error || 'Template name is already in use'}
            </InlineMessage>
          )}
        </FormField>
        <Modal.Actions>
          <Button type="button" variant="tertiary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            type="button"
            variant={templateIdInUse ? 'destructive' : 'primary'}
            onClick={handleSave}
            disabled={!!error || !templateName}
          >
            {templateIdInUse ? 'Overwrite' : 'Create'}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}

SaveTemplateModal.propTypes = {
  templates: PropTypes.arrayOf(TemplateShape),
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleTemplateCreate: PropTypes.func,
  handleTemplateUpdate: PropTypes.func,
};

export default SaveTemplateModal;
