import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TileButton, Button, Icon } from '@appcues/sonar';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowRightArrowLeft';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import {
  generatePresentationStep,
  generateTraitPresentation,
} from 'entities/step-groups';
import { update, templateApply } from 'entities/experiences';
import { AddStepShape, updateAddStepOfType } from 'entities/user-interface';
import {
  selectTemplates,
  update as updateTemplate,
  remove as removeTemplate,
  Shape as TemplateShape,
} from 'entities/templates';
import { isEmbedExperience, EXPERIENCE_TYPES } from 'lib/experiences';
import { EMBED, MODAL, TOOLTIP, TRAIT_LABELS } from 'lib/trait';
import {
  MODES,
  LIST_FIRST_STEP,
  LIST_NEW_STEP,
  generatePreviewStep,
} from 'lib/templates';
import { PLATFORMS, IONIC } from 'lib/platform';
import {
  EMBED_PRESENTATION,
  PRESENTATIONS,
  getAllowedPresentations,
} from 'lib/presentation';
import { Content } from 'components/SideBarSettings/Shared';
import { TemplateList } from 'components/TemplateList';
import PreviewEditor from './PreviewEditor';
import PresentationIcon from './PresentationIcons';
import {
  Actions,
  Wrapper,
  Text,
  Tabs,
  PresentationGroups,
  ToggleGroupWrapper,
  TileGroup,
} from './styled';

export function PresentationSettings({
  experience,
  selectedStepTypeToAdd,
  templates,
  onTemplateApply,
  onUpdate,
  onUpdateAddStepOfType,
  onTemplateUpdate,
  onTemplateRemove,
}) {
  const { track } = useAnalytics();

  const [traitPresentation, setTraitPresentation] = useState();
  const [presentationStep, setPresentationStep] = useState({});

  const { id, type, platform } = experience;
  const isEmbed = isEmbedExperience(type);
  const showPreviewEditor = traitPresentation != null;

  const {
    stepType,
    presentation: stepTypePresentation,
    layoutTrait: stepTypeLayoutTrait,
  } = selectedStepTypeToAdd;

  const handleClick = ({
    layoutTrait,
    presentation,
    content: templateContent,
  }) => {
    const templateName = templateContent ? 'Created custom template' : null;
    const embedName = isEmbed ? 'Created Embeds from Scratch' : null;
    const hasPresentation = presentation != null;
    const baseName = hasPresentation
      ? `Created ${PRESENTATIONS[layoutTrait][presentation]} ${TRAIT_LABELS[layoutTrait]}`
      : null;

    const name = templateName || embedName || baseName;

    track('Mobile Builder interaction', {
      name,
      component: 'Presentations',
    });

    // Template content or "from scratch" default content
    const template =
      templateContent ??
      generateTraitPresentation({ layoutTrait, presentation });

    onTemplateApply({
      mode: stepType === 'child' ? MODES.ADD_CHILD : MODES.ADD_GROUP,
      template,
      presentation: presentation ?? stepTypePresentation,
      skipTraits: !templateContent,
    });

    onUpdateAddStepOfType({});
  };

  const handleTemplateUpdate = ({ id: templateId, name }) => {
    onTemplateUpdate({
      id: templateId,
      ...(name && { name }),
    });
  };

  const handleSwitchTypeClick = () => {
    const [DEFAULT_TYPE, EMBED_TYPE] = EXPERIENCE_TYPES;
    const updatedType = isEmbed ? DEFAULT_TYPE : EMBED_TYPE;
    onUpdate({ id, type: updatedType });
  };

  useEffect(() => {
    if (traitPresentation?.content) {
      setPresentationStep(
        generatePreviewStep({
          content: traitPresentation.content,
          presentation: stepTypePresentation,
        })
      );
    } else if (traitPresentation) {
      setPresentationStep(generatePresentationStep(traitPresentation));
    } else {
      setPresentationStep({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traitPresentation]);

  const $sidebar = useRef(null);
  useClickOutside([$sidebar], () => onUpdateAddStepOfType({}));

  const renderTemplateList = templateListProps => {
    const { filter, ...rest } = templateListProps;
    const filteredTemplates = templates.filter(({ contentType }) =>
      filter(contentType)
    );

    return (
      <TemplateList
        // eslint-disable-next-line @appcues/jsx-props-no-spreading
        {...rest}
        templates={filteredTemplates}
        handlePreview={setTraitPresentation}
        handleClick={handleClick}
        handleTemplateUpdate={handleTemplateUpdate}
        handleTemplateRemove={onTemplateRemove}
      />
    );
  };

  if (stepTypeLayoutTrait) {
    return (
      <>
        {renderTemplateList({
          ref: $sidebar,
          filter: value => value === stepTypeLayoutTrait,
          context: LIST_NEW_STEP,
          emptyTrait: stepTypeLayoutTrait,
          emptyPresentation: stepTypePresentation,
          handleCancel: () => onUpdateAddStepOfType({}),
        })}
        <PreviewEditor
          presentationStep={presentationStep}
          isEmbed={isEmbed}
          visible={showPreviewEditor}
        />
      </>
    );
  }

  return (
    <>
      {platform !== IONIC && (
        <Actions>
          <Button variant="tertiary" onClick={handleSwitchTypeClick}>
            <Icon icon={faArrowRightArrowLeft} />
            {isEmbed ? 'Modals & Tooltips' : 'Embeds'}
          </Button>
        </Actions>
      )}
      <Content>
        {isEmbed ? (
          renderTemplateList({
            filter: value => value === EMBED,
            context: LIST_FIRST_STEP,
            emptyTrait: EMBED,
            emptyPresentation: EMBED_PRESENTATION,
          })
        ) : (
          <Tabs.Root defaultValue="default" align="stretch">
            <Tabs.List>
              <Tabs.Trigger value="default">Default</Tabs.Trigger>
              <Tabs.Trigger value="saved">Saved</Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="default">
              <Wrapper>
                <PresentationGroups>
                  {[MODAL, TOOLTIP].map(layoutTrait => (
                    <Fragment key={layoutTrait}>
                      <Text>{TRAIT_LABELS[layoutTrait].toUpperCase()}</Text>
                      <ToggleGroupWrapper>
                        <TileGroup type="single">
                          {getAllowedPresentations(layoutTrait, platform).map(
                            ([presentation, label]) => (
                              <TileButton
                                key={presentation}
                                label={label}
                                onClick={() =>
                                  handleClick({ layoutTrait, presentation })
                                }
                                onMouseEnter={() =>
                                  setTraitPresentation({
                                    layoutTrait,
                                    presentation,
                                  })
                                }
                                onMouseLeave={() => setTraitPresentation()}
                              >
                                <PresentationIcon
                                  layoutTrait={layoutTrait}
                                  presentation={presentation}
                                />
                              </TileButton>
                            )
                          )}
                        </TileGroup>
                      </ToggleGroupWrapper>
                    </Fragment>
                  ))}
                </PresentationGroups>
              </Wrapper>
            </Tabs.Content>
            <Tabs.Content value="saved">
              {renderTemplateList({
                filter: value => value !== EMBED,
                context: LIST_FIRST_STEP,
              })}
            </Tabs.Content>
          </Tabs.Root>
        )}
      </Content>
      <PreviewEditor
        presentationStep={presentationStep}
        isEmbed={isEmbed}
        visible={showPreviewEditor}
      />
    </>
  );
}

PresentationSettings.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(EXPERIENCE_TYPES),
    platform: PropTypes.oneOf(PLATFORMS),
  }),
  selectedStepTypeToAdd: AddStepShape,
  templates: PropTypes.arrayOf(TemplateShape),
  onTemplateApply: PropTypes.func,
  onUpdate: PropTypes.func,
  onUpdateAddStepOfType: PropTypes.func,
  onTemplateUpdate: PropTypes.func,
  onTemplateRemove: PropTypes.func,
};

const mapStateToProps = state => ({
  templates: Object.values(selectTemplates(state)),
});

const mapDispatchToProps = {
  onTemplateApply: templateApply,
  onUpdate: update,
  onUpdateAddStepOfType: updateAddStepOfType,
  onTemplateUpdate: updateTemplate,
  onTemplateRemove: removeTemplate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentationSettings);
