import { EMBED } from 'lib/trait';
import { CONTENT_TEMPLATE_TYPE, EMBED_CTA } from './defaults';

export default {
  id: EMBED_CTA,
  name: 'Call-to-action',
  type: CONTENT_TEMPLATE_TYPE,
  contentType: EMBED,
  isDefault: true,
  content: {
    id: 'b3e61c11-1bcb-4b27-bfb0-a1bee8d6cd55',
    type: 'group',
    children: [
      {
        id: '5ee52ece-f87a-4c79-8708-ca9b44e0c233',
        type: 'embed',
        content: {
          id: '8f5cdf31-b4ab-4522-beb0-5656d8ebbcfa',
          items: [
            {
              distribution: 'equal',
              id: '777f284c-919b-4291-b5b2-735e2c11a9fa',
              items: [
                {
                  blockType: 'imageWithText',
                  content: {
                    distribution: 'center',
                    id: '613ba64a-e395-4bfe-b6a8-22552e48cabf',
                    items: [
                      {
                        blurHash: '',
                        contentMode: 'fit',
                        id: '1bd84c83-756c-4849-b8d1-9e8656ba500a',
                        imageUrl:
                          'https://images.appcues.com/v1692992744/106160/mtgk6clvoxhkemneflv4.png',
                        intrinsicSize: {
                          height: 192,
                          width: 240,
                        },
                        style: {
                          marginTrailing: 16,
                          verticalAlignment: 'top',
                          width: 64,
                        },
                        type: 'image',
                      },
                      {
                        id: '61c2ed76-907d-477f-8f3f-3ba00fba4bff',
                        items: [
                          {
                            blockType: 'text',
                            content: {
                              id: '92e79eb7-2be6-441e-9b23-8fad6d45d9d9',
                              style: {
                                fontName: 'System Default Semibold',
                                fontSize: 18,
                                foregroundColor: {
                                  light: '#FFFFFF',
                                },
                                horizontalAlignment: 'leading',
                                marginBottom: 8,
                                marginLeading: 0,
                                marginTop: 0,
                                marginTrailing: 0,
                                textAlignment: 'leading',
                              },
                              text: "It's more fun with friends!",
                              type: 'text',
                            },
                            id: '28926fa4-cdb7-491d-a4af-1971d0cc65e9',
                            type: 'block',
                          },
                          {
                            blockType: 'text',
                            content: {
                              id: '86c97452-7328-4e6a-9563-89bee013604f',
                              style: {
                                fontSize: 16,
                                foregroundColor: {
                                  light: '#FFFFFF',
                                },
                                horizontalAlignment: 'leading',
                                marginBottom: 0,
                                marginLeading: 0,
                                marginTop: 0,
                                marginTrailing: 0,
                                textAlignment: 'leading',
                              },
                              text: 'Invite your friends to the app to begin sharing content.',
                              type: 'text',
                            },
                            id: '53a41057-87d7-451d-849d-4835554cc349',
                            type: 'block',
                          },
                        ],
                        orientation: 'vertical',
                        style: {
                          horizontalAlignment: 'leading',
                        },
                        type: 'stack',
                      },
                    ],
                    orientation: 'horizontal',
                    style: {
                      horizontalAlignment: 'leading',
                      marginLeading: 0,
                      marginTop: 8,
                      marginTrailing: 8,
                      paddingBottom: 8,
                      paddingLeading: 16,
                      paddingTop: 8,
                      paddingTrailing: 16,
                      verticalAlignment: 'top',
                    },
                    type: 'stack',
                  },
                  id: '4a563bc3-a4a7-4c5e-a26a-3161e7226e12',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
            {
              distribution: 'equal',
              id: '317e5da2-43c0-4561-8949-81412619a4c7',
              items: [
                {
                  blockType: 'button',
                  content: {
                    content: {
                      id: '1bb5e593-4cc3-4db2-8d83-e1c0932b8daa',
                      style: {
                        fontName: 'System Default Bold',
                        fontSize: 17,
                        foregroundColor: {
                          light: '#165196',
                        },
                      },
                      text: 'Invite now',
                      type: 'text',
                    },
                    id: '1b72381f-9711-4376-a4c8-f11a85c73bef',
                    style: {
                      backgroundColor: {
                        light: '#FFFFFF',
                      },
                      cornerRadius: 26,
                      horizontalAlignment: 'trailing',
                      marginBottom: 8,
                      marginLeading: 16,
                      marginTop: 8,
                      marginTrailing: 16,
                      paddingBottom: 8,
                      paddingLeading: 16,
                      paddingTop: 8,
                      paddingTrailing: 16,
                    },
                    type: 'button',
                  },
                  id: '2fdbd0fb-61f9-43e6-ba07-1de71af39660',
                  type: 'block',
                },
              ],
              orientation: 'horizontal',
              role: 'row',
              type: 'stack',
            },
          ],
          orientation: 'vertical',
          style: {},
          type: 'stack',
        },
        actions: {
          '1b72381f-9711-4376-a4c8-f11a85c73bef': [
            {
              config: {},
              on: 'tap',
              type: '@appcues/continue',
            },
          ],
        },
        traits: [],
      },
    ],
    actions: {},
    traits: [
      {
        config: {
          presentationStyle: 'embed',
          style: {
            backgroundColor: {
              light: '#165196',
            },
            cornerRadius: 12,
            marginBottom: 0,
            marginLeading: 12,
            marginTop: 0,
            marginTrailing: 12,
            paddingBottom: 18,
            paddingTop: 10,
          },
          transition: 'fade',
        },
        type: '@appcues/embedded',
      },
      {
        config: {
          buttonAppearance: 'minimal',
          buttonStyle: {
            foregroundColor: {
              light: '#FFFFFF',
            },
          },
        },
        type: '@appcues/skippable',
      },
      {
        type: '@appcues/carousel',
      },
      {
        config: {
          style: {
            backgroundColor: {
              light: '#CCCCCC',
            },
            foregroundColor: {
              light: '#999999',
            },
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
          },
        },
        type: '@appcues/paging-dots',
      },
    ],
  },
};
