import { EMBED, CAROUSEL, SKIPPABLE_OPTIONS } from 'lib/trait';
import { skippableTemplate } from './skippable';
import { pagingDotsTemplate } from './paging-dots';

export const embedTemplate = presentation => {
  const style = {
    backgroundColor: { light: '#FFFFFF' },
    paddingBottom: 16,
  };

  return [
    {
      type: EMBED,
      config: {
        frameID: '',
        transition: 'fade',
        presentationStyle: presentation,
        style,
      },
    },
    skippableTemplate(SKIPPABLE_OPTIONS.MINIMAL),
    { type: CAROUSEL },
    pagingDotsTemplate,
  ];
};
