import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { insert, LOAD_TEMPLATE_SCREENSHOT, reject } from './actions';
import { getImageHashKeys } from './lib';

export function* fetchTemplateScreenshot({ payload: { id, version } }) {
  try {
    const api = yield getContext('api');
    const image = yield call(api.getLambdaTemplateImage, id);

    yield put(
      insert({
        id: getImageHashKeys({ id, version }),
        url: URL.createObjectURL(image),
      })
    );
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(LOAD_TEMPLATE_SCREENSHOT, fetchTemplateScreenshot);
}
